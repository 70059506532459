import React, { Fragment, useEffect, useState } from 'react';
import './mytests.scss';
import { Card } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/mdi_menu-down.svg' alt='' />
        </components.DropdownIndicator>
    );
};

const SelectQuestions = ({ testName, duration, isQuestionLoading, additionalQuestions, fetchData, total, goBackNewTest, backTomyTest, selectOneQuestion, selectedQuestion, removeQuestion,
    saveSelectQuestion, skills, topics, setSkill, setTags, handleSearch, saveTest, addToAssessment, openEdit, editDetails, editMyTest, isLoading }) => {

    const [manuallTestSummary, setManuallTestSummary] = useState();
    const [query, setQuery] = useState('');
    const [area, setArea] = useState('');
    const [topic, setTopic] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const firstRender = async () => {
            await setManuallTestSummary(editDetails ? true : false);
        }
        firstRender();
    }, [editDetails])


    const manuallsummary = () => {
        setManuallTestSummary(true);
        saveSelectQuestion(selectedQuestion);
    }

    const selectQuestion = (e, detail) => {
        selectOneQuestion(e.target.value, e.target.checked, detail)
    }

    const changeSearch = (val) => {
        setQuery(val);
        handleSearch(val);
    }

    const selectSkill = async (e) => {
        await setArea(e);
        await setSkill(e);
    }

    const selectTopic = async (e) => {
        await setTopic(e);
        await setTags(e)
    }


    return (
        <Fragment>
            {isLoading ?
                t('Loading..',{ns: 'home'}) :
                manuallTestSummary ?
                    (
                        <div className='newtests'>
                            <div className='textclass d-flex justify-content-center align-items-center'>
                                <img className='backarrow manuallback' src='/images/back.svg' onClick={() => {
                                    goBackNewTest();
                                    editDetails && setManuallTestSummary(false)
                                }} alt='' />
                                <h1 className='newtestpage'>{t('Test Summary',{ns: 'mytest'})}</h1>
                            </div>
                            <div style={{ gap: '10px' }}>

                                <Card className='summarycard'>
                                    <div className='summaryheader d-flex justify-content-center align-items-center'>
                                        <div className='d-flex headeritem'>
                                            <img className='editimg' src='/images/info-green.svg' alt='' />&nbsp;&nbsp;
                                            <div className='' >
                                                <p>{t('Test Name',{ns: 'mytest'})}</p>
                                                <div className='d-flex justify-content-center align-items-center mt-1' >
                                                    <h4>{testName}</h4> &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <img className='cursor-pointer' src='/images/edit-icon.svg' alt='' onClick={openEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex headeritem'>
                                            <img className='editimg' src='/images/ph_question-light.svg' alt='' />
                                            <div className='' >
                                                <p>{t('Questions',{ns: 'mytest'})}</p>
                                                <div className='text-center mt-1' >
                                                    <h4>{selectedQuestion?.length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex headeritem'>
                                            <img className='editimg' src='/images/lets-icons_time-progress-light.svg' alt='' />&nbsp;&nbsp;
                                            <div className='' >
                                                <p>{t('Minutes',{ns: 'mytest'})}</p>
                                                <div className='d-flex  justify-content-center align-items-center mt-1' >
                                                    <h4>{duration}</h4> &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <img className='cursor-pointer' src='/images/edit-icon.svg' onClick={openEdit} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                                <Card className='manuallquestionsbody'>

                                    <div className='manuallsummarybody'>
                                        <div className='d-flex justify-content-between align-items-center' style={{ gap: "20px" }}>
                                            <h3  >{t('Question list',{ns: 'mytest'})}</h3>
                                            <h2 className='cursor-pointer' onClick={() => { setManuallTestSummary(false) }} >{t('ADD QUESTIONS',{ns: 'mytest'})}</h2>
                                        </div>
                                        {

                                            selectedQuestion?.length > 0 ?

                                                <div className="question-list">
                                                    {selectedQuestion && selectedQuestion?.map(el => {
                                                        return (
                                                            <div className='question-selected'>
                                                                <ul key={el.id}>
                                                                    <li className=''>
                                                                        <h4 >{el.skillName}</h4>
                                                                        <p  >{el.skillTopicName}</p>
                                                                    </li>
                                                                    <li>
                                                                        <h5 className='elipsis-3' dangerouslySetInnerHTML={{ __html: el?.question }} />
                                                                    </li>
                                                                    <li>
                                                                        <img src='/images/delete-icon.svg' className='cursor-pointer' alt='' onClick={() => removeQuestion(el)} />
                                                                    </li>
                                                                    <hr />
                                                                </ul>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                :
                                                <div className='empty-question'>
                                                    <img src='/images/magnifier.svg' alt='' />
                                                    <div className='mt-2'>
                                                        <h3>{t('There is no selected tests',{ns: 'mytest'})}</h3>
                                                    </div>
                                                </div>

                                        }
                                    </div>

                                </Card>
                                <div className='testpagebtn' >
                                    <button className='submitbtn' onClick={editDetails ? editMyTest : saveTest} >{t('SAVE & EXIT',{ns: 'mytest'})}</button>
                                    <button className='assbtn' onClick={addToAssessment}>{t('ADD TO ASSESSMENT',{ns: 'mytest'})}</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='newtests'>
                            <Card className='headclass' >
                                <div className='manullhead' >
                                    <img className='manuallback' src='/images/back.svg' onClick={goBackNewTest} alt='' />
                                    <h3 className='manullheadmain'>{t('Add Questions',{ns: 'mytest'})}</h3>
                                    <p className='manullheadpara' >{t('VIEW SELECTION',{ns: 'mytest'})}({selectedQuestion?.length})</p>
                                    <button className='manuallbtn' onClick={manuallsummary} >{t('DONE',{ns: 'mytest'})}</button>
                                </div>
                                <div className='manuallsearch search-tests mt-3'>
                                    <div className="has-search">
                                        <span className="form-control-feedback">
                                            <img src="/images/search.svg" alt='' width={12} />
                                        </span>
                                        <input type="search" className="form-control" placeholder={t('Search for test',{ns: 'mytest'})} value={query} onChange={(e) => changeSearch(e.target.value)} />
                                    </div>
                                    <div className='d-flex align-items-center' style={{ gap: 5 }} >
                                        <div style={{ width: 165 }}>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                placeholder={t('Knowledge Area',{ns: 'mytest'})}
                                                options={skills}
                                                value={area}
                                                onChange={(e) => selectSkill(e)}
                                                isClearable
                                            />
                                        </div>
                                        <div style={{ width: 165 }} >
                                            <Select
                                                components={{ DropdownIndicator }}
                                                placeholder={t('Topics / Tags',{ns: 'mytest'})}
                                                options={topics}
                                                value={topic}
                                                onChange={(e) => selectTopic(e)}
                                                isClearable
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <InfiniteScroll
                                dataLength={additionalQuestions && additionalQuestions?.length}
                                next={total > additionalQuestions?.length ? fetchData : ''}
                                hasMore={true}
                            >

                                <div>
                                    {isQuestionLoading
                                        ?
                                        t('Loading..',{ns: 'home'})
                                        :
                                        additionalQuestions?.length > 0 ?
                                            additionalQuestions && additionalQuestions?.map(el => {
                                                return (
                                                    <Card className='mannual-question-list'>
                                                        <div className='ques-item'>
                                                            <div className='ques-title'>
                                                                <input type='checkbox' className='checkbox' value={el.questionID} checked={el.isChecked} onChange={(e) => selectQuestion(e, el)} />&nbsp;&nbsp;&nbsp;
                                                                <div  >
                                                                    <p className='manuallquestion' >{el.skillName}</p>
                                                                    <p className='elipsis-2' dangerouslySetInnerHTML={{ __html: el?.question }} />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                {el.questionTypeName === 'Multiple Choice' ?
                                                                    <img src='/images/mcq.svg' alt='' /> :
                                                                    <img src='/images/essay.svg' alt='' />
                                                                }
                                                                {el.questionTypeName === 'Multiple Choice' ? <p>{t('Multiple choice',{ns: 'mytest'})}</p> : <p>{t('Essay Type',{ns: 'mytest'})}</p>}
                                                            </div>
                                                            <img className='manuallinfo' src='/images/info.svg' alt='' />
                                                        </div>
                                                    </Card>
                                                )
                                            }) :
                                            'No Question Found'
                                    }
                                </div>
                            </InfiniteScroll>
                            <button className='manuallbtn mt-2' onClick={manuallsummary} >{t('DONE',{ns: 'mytest'})}</button>
                        </div>
                    )}
        </Fragment>

    )
}

export default SelectQuestions;
