/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CardContent, Checkbox, Collapse, Dialog, FormControl, FormControlLabel, IconButton, Stack, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandMore } from '@mui/icons-material';
import { Button } from 'react-bootstrap';
import { getQuestionTags, getCodingQuestionByID, getCodingQuestionLanguages, getCodingQuestionReturnTypes, getQuestionLevels, postCreateCodingQuestion, postUpdateCodingQuestion } from '../../Redux/ActionCreators/testLibraryAction';
import JoditEditor from 'jodit-react';
import { returnErrors } from '../../Redux/ActionCreators/errorAction';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';


const UI_STRINGS = {
    "OUTPUT": 'output',
    "TIME": 'time',
    "DIFFICULTY": 'difficulty',
}

const save_button_style = {
    backgroundColor: 'var(--button-color)',
    color: '#fff',
    borderRadius: 0,
    height: 48,
    border: 'none',
    fontSize: 14,
    padding: '10px 30px',
    minWidth: 150,
    marginBottom: 50
}

const CreateCodingQuestion = (props) => {
    const details = JSON.parse(localStorage.getItem("profileDetails"));
    const [funcName, setFuncName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const descriptionEditorRef = useRef(null);
    const { questionLevel, qTags, codingQuestionReturnTypesOptions, codingQuestionLanguagesOptions, codingQuestionByIdResponse, loadingCodingQuestionById } = useSelector(state => state.testLibrary);

    const [questionTitle, setQuestionTitle] = useState('');
    const [questionDescription, setQuestionDescription] = useState('');
    const [questionDescriptionError, setQuestionDescriptionError] = useState('');

    const [programmingLanguages, setProgrammingLanguages] = useState([]);
    const [tags, setTags] = useState([]);


    const [questionWeightage, setQuestionWeightage] = useState(20);

    const [timeDuration, setTimeDuration] = useState(10);

    const [selectOutput, setSelectOutput] = useState(1);

    const [selectDifficulty, setSelectDifficulty] = useState(1);

    const [inputs, setInputs] = useState([{ inputName: 'n', inputTypeId: 1 }]);

    const [expanded, setExpanded] = useState(true);

    const [testCases, setTestCases] = useState([
        { isDefault: 1, output: '', inputs: [{ input: '' }] },
        { isDefault: 0, output: '', inputs: [{ input: '' }] }
    ]);

    useEffect(() => {
        dispatch(getQuestionTags())
    }, [])

    useEffect(() => {

        if (codingQuestionByIdResponse) {
            // console.log('codingQuestionByIdResponse', codingQuestionByIdResponse)
            const { question } = codingQuestionByIdResponse;
            setQuestionTitle(question?.name);
            setQuestionDescription(question?.description);
            setProgrammingLanguages(question?.languages?.map(el => [{ label: el.displayLanguage, value: el.codingLanguageId }][0]));
            setQuestionWeightage(question?.score);
            setTimeDuration(question?.duration);
            setSelectOutput(question?.outputReturnTypeId);
            setSelectDifficulty(question?.difficultyLevelId);
            setInputs(question?.inputs);
            setTags(question?.tags?.map(el => [{ label: el, value: el }][0]));
            setFuncName(question?.functionName);
            question?.testCases?.forEach(testCase => {
                testCase.inputs.sort((a, b) => a.id - b.id);
            })
            setTestCases(question?.testCases);
        }
        if (!location.state.edit) {
            resetStates();
        }
        return (() => {
            // resetStates();
        })

    }, [codingQuestionByIdResponse])

    const handleExpandClick = () => {
        setExpanded((expanded) => !expanded);
    };

    const handleSelectChange = (select_name, event) => {
        if (select_name === UI_STRINGS.OUTPUT) {
            setSelectOutput(event?.target?.value);
        }
        if (select_name === UI_STRINGS.DIFFICULTY) {
            setSelectDifficulty(event?.target?.value);
        }
    }
    const selectLanguage = async (e) => {
        if (e.some(el => el.value === 'all')) {
            await setProgrammingLanguages(codingQuestionLanguagesOptions?.map(el => [{ label: el.displayLanguage, value: el.id }][0]));
        } else {
            await setProgrammingLanguages(e);
        }
    }

    const handleTags = (e) => {
        console.log(e)
        setTags(e)
    }

    const handleInputs = (index, key, event) => {
        const value = event?.target?.value;
        let inputs_copy = [...inputs];
        inputs_copy[index][key] = value;
        setInputs(inputs_copy);
    };

    // const handleTestCase = (case_index, input_index, key, event) => {
    //     const value = event?.target?.value;
    //     let testCases_copy = [...testCases];
    //     testCases_copy[case_index].inputs[input_index][key] = value;
    //     setTestCases(testCases_copy);
    // };
    const handleTestCase = (case_index, input_index, key, event) => {
        const value = event?.target?.value;
        let testCases_copy = [...testCases];

        // Ensure inputs array has an element at input_index
        if (!testCases_copy[case_index].inputs[input_index]) {
            testCases_copy[case_index].inputs.push({ input: '' });
        }

        testCases_copy[case_index].inputs[input_index][key] = value;
        setTestCases(testCases_copy);
    }

    const defaultTestCaseChangeHandler = (case_index, event) => {
        let testCases_copy = [...testCases];
        testCases_copy?.forEach((test_case, index) => {
            if (case_index === index) {
                test_case.isDefault = 1;
            } else {
                test_case.isDefault = 0;
            }
        })
        setTestCases(testCases_copy);
    };

    const testCaseOutputChangeHandler = (case_index, event) => {
        const value = event?.target?.value;
        let testCases_copy = [...testCases];
        testCases_copy[case_index].output = value;
        setTestCases(testCases_copy);
    };

    const deleteInputHandler = (index) => {
        let inputs_copy = [...inputs];
        inputs_copy.splice(index, 1);
        setInputs(inputs_copy);
    }

    const validateInputOnBlur = (input, index) => {
        if (!input?.inputName) {
            deleteInputHandler(index);
        };
    }

    const validateForm = () => {

        if (!questionTitle) {
            // setQuestionTitleError('Question name required!');
            returnErrors(dispatch, 'Question name is required!', 500);
            return false;
        }
        else if (!questionDescription) {
            returnErrors(dispatch, (t('Question description is required!')), 500);
            return false;
        }
        else if (!funcName) {
            returnErrors(dispatch, (t('Function Name is required!')), 500);
            return false;
        }
        else if (!selectOutput) {
            returnErrors(dispatch, (t('Question output is required!')), 500);
            return false;
        }
        else if (!timeDuration) {
            returnErrors(dispatch, (t('Question duration is required!')), 500);
            return false;
        }
        else if (!selectDifficulty) {
            returnErrors(dispatch, (t('Question difficulty is required!')), 500);
            return false;
        }
        else if (!questionWeightage) {
            returnErrors(dispatch, (t('Question score is required!')), 500);
            return false;
        }
        else if (programmingLanguages?.length === 0) {
            returnErrors(dispatch, (t('Atleast 1 programming is requires!')), 500);
            return false;
        }
        else if (tags?.length === 0) {
            returnErrors(dispatch, (t('Atleast 1 tag is requires!')), 500);
            return false;
        }
        else if (inputs?.length === 0) {
            returnErrors(dispatch, (t('Atleast 1 input is requires!')), 500);
            return false;
        }
        else if (testCases?.length === 0) {
            returnErrors(dispatch, (t('Atleast 2 test cases is requires!')), 500);
            return false;
        }

        //* checking if Input have empty fields
        const hasInvalidInput = inputs.some((input) => input?.inputName === '');
        if (hasInvalidInput) {
            returnErrors(dispatch, (t("Input name can't be empty")), 500);
            return false;
        }


        //* checking if test-cases have empty fields
        const isEmptyTestCase = testCases?.some((testCase) =>
            testCase?.output === '' || testCase?.inputs?.every((input_) => input_?.input === '')
        );
        if (isEmptyTestCase) {
            returnErrors(dispatch, (t("Test cases fields can't be empty")), 500);
            return false;
        }

        return true;

    }

    const questionSaveHandler = async () => {
        await setLoading(true);
        const is_formValid = validateForm();
        if (is_formValid) {
            let request_data = {
                "tenantId": parseInt(details?.tenentId),
                "name": questionTitle,
                "description": questionDescription,
                "outputReturnTypeId": selectOutput,
                "duration": timeDuration,
                "difficultyLevelId": selectDifficulty,
                "weightage": questionWeightage,
                "languages": programmingLanguages.map(el => [{ codingLanguageId: el.value }][0]),
                "inputs": inputs,
                "tags": tags.map(el => el.value),
                "testCases": testCases,
                functionName: funcName,
                addFromList: props.addFromList ? true : null
            }
            if (location?.state?.edit) {
                request_data.id = location?.state?.detail?.id;
                const response = await dispatch(postUpdateCodingQuestion({ ...request_data, id: location.state.detail?.id }));
                if (response.type === 'UPDATE_CODING_QUESTION') {
                    navigate(-1)
                }
            } else {
                const response = await dispatch(postCreateCodingQuestion(request_data));
                if (response?.type === 'CREATE_CODING_QUESTION') {
                    props.addFromList ? props.goBack() : navigate(-1)
                }
            }
            await setLoading(false);
        }
        await setLoading(false);
    }
    const resetStates = () => {
        setQuestionTitle('');
        setQuestionDescription('');
        setProgrammingLanguages([]);
        setQuestionWeightage(20);
        setTimeDuration(10);
        setSelectOutput(1);
        setSelectDifficulty(1);
        setTags([])
        setInputs([{ inputName: 'n', inputTypeId: 1 }]);
        setTestCases([
            { isDefault: 1, output: '', inputs: [{ input: '' }] },
            { isDefault: 0, output: '', inputs: [{ input: '' }] }
        ]);
    }

    //* if the useris not admin the go back
    // useEffect(() => {
    //     if (details?.tenentId !== "-1" && details?.roleId !== "1") {
    //         navigate(-1);
    //     }
    // }, [details])


    useEffect(() => {
        if (location?.state?.detail?.id) {
            dispatch(getCodingQuestionByID(location?.state?.detail?.id));
        }
        dispatch(getCodingQuestionLanguages());
        dispatch(getCodingQuestionReturnTypes());
        dispatch(getQuestionLevels());
    }, [])



    console.log(testCases)
    return (<div>

        <div className="bg-white rounded mt-3 mb-3 px-5 py-3 create-question-main">
            <div className="row">
                <div className="col-5">
                    <img src='/images/back.svg' alt='' className='cursor-pointer' onClick={() => window.location.pathname.includes('test-library') ? props.goBack() : navigate(-1)} />
                </div>
                <div className="col-7">
                    <h1 className="maintxt"> {location?.state?.view ? (t('View Question')) : location?.state?.edit ? (t('Edit Question')) : (t('Create Question'))}</h1>
                </div>
            </div>
            <br />

            <Stack spacing={5}>
                {/* <TextField fullWidth label={<b>Question Name</b>} id="fullWidth" /> */}
                <div className='form-group mt-3'>
                    <label className="labeltext px-0">{t('Question Name')}*</label>
                    <input type='text' required value={questionTitle} className='form-control mt-1' onChange={(e) => { setQuestionTitle(e.target.value) }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <label className="labeltext px-0">{t('Question Description')}</label>
                    <div className="row mb-2">
                        <div className="col-12">
                            <JoditEditor
                                config={null}
                                ref={descriptionEditorRef}
                                value={questionDescription}
                                onChange={newContent => {
                                    setQuestionDescription(newContent)
                                    setQuestionDescriptionError('')
                                }}
                            />
                            {/* <Editor content={questionDescription} type='title' /> */}
                            {questionDescriptionError && <small style={{ color: 'red' }}>{questionDescriptionError}</small>}
                        </div>
                    </div>
                </div>
                <Stack spacing={2}>
                    <div><label className="labeltext px-0">{t('Function Name')}*</label></div>
                    <div className='mt-2'>
                        <input type='text' value={funcName} className='form-control mt-2' onChange={(e) => { setFuncName(e.target.value) }} />
                    </div>
                </Stack>

                <Stack spacing={2}>
                    <div><label className="labeltext px-0">{t('Programming Languages')}*</label></div>
                    <div className='mt-2'>
                        <Select
                            isMulti
                            placeholder={t('Select Languages')}
                            options={[{ label: 'Select all', value: 'all' }, ...codingQuestionLanguagesOptions?.map(el => [{ label: el.displayLanguage, value: el.id }][0])]}
                            value={programmingLanguages}
                            onChange={(e) => selectLanguage(e)} />
                    </div>
                </Stack>
                <Stack spacing={2}>
                    <div><label className="labeltext px-0">{t('Tags')}*</label></div>
                    <div className='mt-2'>
                        <CreatableSelect
                            options={qTags}
                            isMulti
                            value={tags}
                            placeholder={t('Enter tags')}
                            onChange={(e) => handleTags(e)} />
                    </div>
                </Stack>
            </Stack>
            <br />

            <Box sx={{ flexGrow: 2, display: 'flex', alignItems: 'baseline', gap: 1, }}>
                <FormControl sx={{ m: 1, width: 250 }} variant="outlined" size="small">
                    <div>
                        <label className="labeltext px-0">{t('Time* (Minutes)')}</label>
                        <input type='number' value={timeDuration} className='form-control mt-2' style={{ padding: 8 }} onChange={(e) => { setTimeDuration(e.target.value) }} />
                    </div>
                </FormControl>
                <FormControl sx={{ m: 1, width: 250 }} variant="outlined" size="small">
                    <div>
                        <label className="labeltext px-0">{t('Score* (Marks)')}</label>
                        <input type='number' value={questionWeightage} className='form-control mt-2' style={{ padding: 8 }} onChange={(e) => { setQuestionWeightage(e.target.value) }} />
                    </div>
                </FormControl>
            </Box><br />
            <Box sx={{ flexGrow: 2, display: 'flex', alignItems: 'baseline', gap: 1 }}>
                <FormControl sx={{ m: 1, width: 250 }} variant="outlined" size="small">
                    <label className="labeltext px-0" id="output-simple-select-label">{t('Output* (Return type)')}</label>
                    <div className='mt-2'>
                        <select
                            defaultValue={0}
                            labelId="output-simple-select-label"
                            id="output-simple-select"
                            className='form-control'
                            value={selectOutput}
                            onChange={(event) => handleSelectChange(UI_STRINGS.OUTPUT, event)}
                        >
                            {codingQuestionReturnTypesOptions?.returnTypes?.map((option, i) => {
                                return (
                                    <option key={i} value={option?.id}>{option?.retrunType}</option>
                                )
                            })}
                        </select>
                    </div>
                </FormControl>

                <FormControl sx={{ m: 1, width: 250 }} variant="outlined" size="small">
                    <div><label className="labeltext px-0" id="level-simple-select-label">{t('Difficulty* (Level)')}</label></div>
                    <div className='mt-2'>
                        <select
                            defaultValue={0}
                            labelId="level-simple-select-label"
                            id="level-simple-select"
                            className='form-control'
                            value={selectDifficulty}
                            onChange={(event) => handleSelectChange(UI_STRINGS.DIFFICULTY, event)}
                        >
                            {questionLevel?.map((option, i) => {
                                return (
                                    <option key={i} value={option?.questionLevelID}>{option?.questionLevelName}</option>
                                )
                            })}

                        </select>

                    </div>
                </FormControl>

            </Box>
            <br />

            <Box sx={{ flexGrow: 1 }}>
                <div className='form-group mt-3'>
                    <label className="labeltext px-0">{t('Inputs')}*</label>
                    {inputs?.map((input, i) => {
                        return (
                            <Box key={i}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}
                            >  <FormControl sx={{ m: 1, width: 250 }} variant="outlined" size="small">
                                    <TextField id="input-basic" label="Input name" required variant="outlined" size="small" value={input?.inputName} onBlur={() => validateInputOnBlur(input, i)} onChange={(event) => handleInputs(i, 'inputName', event)} />
                                </FormControl>
                                <FormControl required sx={{ m: 1, minWidth: 250 }} variant="outlined" size="small">
                                    {/* <InputLabel id="input-type-simple-select-label">
                                        Input type
                                    </InputLabel> */}
                                    <select
                                        placeholder=''
                                        defaultValue={1}
                                        className='select-input form-control'
                                        labelId="input-type-simple-select-label"
                                        label="Input type"
                                        id="input-type-simple-select"
                                        value={input?.inputTypeId}
                                        onChange={(event) => handleInputs(i, 'inputTypeId', event)}
                                    >
                                        {codingQuestionReturnTypesOptions?.returnTypes?.map((option, i) => {
                                            return (
                                                <option key={i} value={option?.id}>{option?.retrunType}</option>
                                            )
                                        })}

                                    </select>
                                </FormControl>
                                {i !== 0 && <IconButton onClick={() => deleteInputHandler(i)} aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>}
                            </Box>
                        )
                    })}
                    <Button style={{ color: '#009199', fontWeight: 'bold' }} onClick={() => setInputs([...inputs, { inputName: '', inputTypeId: 1 }])} variant="outlined">{t('Add more input')}</Button>
                </div>
            </Box>
        </div>

        <div className='bg-white rounded mt-3 mb-3 px-5 py-3 create-question-main'>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    cursor: 'pointer',
                }}
                onClick={handleExpandClick}
            >
                <ExpandMore
                    expand={expanded?.toString()}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show test cases"
                > <ExpandMoreIcon /></ExpandMore>

                <h3 className='text-start'>{t('Test Cases*')}</h3>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {testCases?.map((testCase, index) => {
                        return (
                            <Box key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    borderRadius: 2,
                                    gap: 2,
                                    bgcolor: 'background.paper',
                                    padding: 2,
                                    marginBlock: 1
                                }}
                            >
                                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h6 className='text-start'>{t('Test case')} {index + 1}</h6>
                                    <div>
                                        {index > 1 && <IconButton onClick={() => {
                                            let testCases_copy = [...testCases];
                                            testCases_copy.splice(index, 1);
                                            setTestCases(testCases_copy);
                                        }
                                        } aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>}
                                    </div>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    flexWrap: 'wrap'
                                }}
                                >
                                    {inputs?.map((input_, i) => {
                                        return (
                                            <Box key={i}
                                                sx={{
                                                    display: 'flex',
                                                    gap: 2,
                                                    alignItems: 'end'
                                                }}
                                            >
                                                <FormControl sx={{ m: 1, minWidth: 150 }} variant="outlined" size="small">
                                                    <Typography>
                                                        {t('Input name')} {i + 1}  : {input_?.inputName}
                                                    </Typography>
                                                </FormControl>
                                                <FormControl sx={{ m: 1, width: 250, mr: 10 }} variant="outlined" size="small">
                                                    <TextField id="input-basic" type="text" required variant="outlined" size="small" value={testCase?.inputs[i]?.input} onChange={(event) => handleTestCase(index, i, 'input', event)} />
                                                </FormControl>
                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        alignItems: 'end'
                                    }}
                                >
                                    <FormControl sx={{ m: 1, width: 150 }} variant="outlined" size="small">
                                        <Typography>
                                            {t('Output')}
                                        </Typography>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: 250 }} variant="outlined" size="small">
                                        <TextField id="input-basic" required variant="outlined" size="small" value={testCase?.output} onChange={(event) => testCaseOutputChangeHandler(index, event)} />
                                    </FormControl>
                                </Box>
                                <FormControlLabel control={<Checkbox size="small" checked={testCase?.isDefault ? true : false} onChange={(event) => defaultTestCaseChangeHandler(index, event)} />} label="Default" />
                            </Box>
                        )
                    })}

                </CardContent>
            </Collapse>
            {expanded && <Button style={{ color: '#009199', fontWeight: 'bold' }} onClick={() => setTestCases([...testCases, { isDefault: 0, inputs: [{ input: '' }] }])} variant="outlined">{t('Add more test cases')}</Button>}
        </div>
        {!location?.state?.view && <div style={{ textAlign: 'end' }}> <button style={save_button_style} disabled={loading} onClick={() => questionSaveHandler()}>{t('Save')}</button></div>}
        {((location?.state?.view) || (location?.state?.edit)) && <Dialog
            className='screen-loader'
            open={loadingCodingQuestionById}
            onClose={() => { }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {t('Loading')}...
        </Dialog>}
    </div>)
}

export default CreateCodingQuestion