// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Popover, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

//* selector button component for admin
export const ConditionalCreateQuestionButton = ({ openCreate, navigate }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleCreateCodingClick = () => {
        navigate('/create-coding-question', { state: { edit: false, question: true, detail: null } });
    }

    return (
        <div>
            <button onClick={handleClick}>
                {t('Create Question',{ns: 'mytest'})}
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                fit-content
            >
                <Box component="section" width={198} p={2}>
                    <Stack spacing={2}>
                        <Button onClick={handleCreateCodingClick} variant="outlined">{t('Coding',{ns: 'mytest'})} </Button>
                        <Button onClick={openCreate} variant="outlined"> MCQ </Button>
                    </Stack>
                </Box>
            </Popover>
        </div>
    );
}