import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { verifyEmail } from '../Redux/ActionCreators/authAction';
import { clearErrors } from '../Redux/ActionCreators/errorAction';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Home() {

    const error = useSelector(state => state.error);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const firstRender = async () => {
            if (window.location.pathname.split('/')[2]) {
                const id = atob(window.location.pathname.split('/')[2])
                const res = await dispatch(verifyEmail(id));
                if (res?.type === 'VERIFY_EMAIL') {
                    navigate('/home')
                }
            }
        }

        firstRender();
        if (localStorage.getItem('token')) {
            (window.location.pathname === '/' || window.location.pathname === '/login') && (
                navigate('/home')
            )
        } else {
            window.location.pathname === '/' && (
                navigate('/login')
            )
        }
    }, [])

    const snackClosed = () => {
        dispatch(clearErrors());
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={error.successMessage}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.successMessageText}
                </Alert>
            </Snackbar>
            <div style={{ background: '#fff', width: '100%', height: '100vh', }}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <h1>{t('Link may be invalid.Please verify again',{ns: 'profile'})}</h1>
                </div>
            </div>
        </div>
    )
}
