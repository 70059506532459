/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import "../Login/login.scss";
import { BallBeat } from '../../Loader/loader';
import Snackbar from '@mui/material/Snackbar';
import { userSignupwithCoupon } from '../../Redux/ActionCreators/authAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { freshsales_new_contact } from '../../utils/freshSales';
import { useTranslation } from 'react-i18next';


function CouponRedeem() {
    const [cname, setCname] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [coupon, setCoupon] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [cnameError, setCnameError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [couponError, setCouponError] = useState('');
    const [successEnable, setSuccessEnable] = useState(false);
    const { t } = useTranslation();
    const [successMessageText, setSuccessMessageText] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const error = useSelector(state => state.error)
    useEffect(() => {

        if (localStorage.getItem('token')) {
            (window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/signup' || window.location.pathname === '/couponredeem') && (
                navigate('/home')
            )
        } else {
            window.location.pathname === '/' && (
                navigate('/couponredeem')
            )
        }

    }, [])

    const oncnameChange = (e) => {
        setCname(e.target.value);
        setCnameError('');
    }

    const onNameChange = (e) => {
        setNameError('');
        setName(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setPasswordError('');
        setPassword(e.target.value);
    }

    const onCouponChange = (e) => {
        setCouponError('');
        setCoupon(e.target.value);
    }


    const signupUser = async () => {
        setIsLoading(true);
        setResponseStatus('')
        let emailvalue = email;
        // eslint-disable-next-line no-useless-escape
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (cname === '') {
            setCnameError(t(`Company Name can't be blank`));
            setIsLoading(false);
            return false;
        }
        else if (name === '') {
            setNameError(t(`Name can't be blank`));
            setIsLoading(false);
            return false;
        }
        else if (!emailvalue) {
            setEmailError(t(`Email can't be blank`));
            setIsLoading(false);
            return false;
        }
        else if (!emailrex.test(String(emailvalue))) {
            setEmailError(t("Email is not valid"));
            setIsLoading(false);
            return false;
        }
        // else if (!validemail.test(String(emailvalue.toLowerCase()))) {
        //     setEmailError("Please enter only work email address");
        //     setIsLoading(false);
        //     return false;
        // }
        else if (password === "") {
            setPasswordError(t('Please enter password'));
            setIsLoading(false);
        }
        else if (coupon === "") {
            setCouponError(t('Please enter coupon code'));
            setIsLoading(false);
        }
        else {
            let signup = {
                "companyName": cname,
                "name": name,
                "emailId": email,
                "couponCode": coupon,
                "password": password
            }

            const res = await dispatch(userSignupwithCoupon(signup));
            if (res?.type === 'SIGNUP_USER') {
                await freshsales_new_contact(name, email, 'couponredeem', coupon);
                setTimeout(() => {
                    if (error.successMessage === false) {
                        setIsLoading(false);
                    }
                }, 1000);
            } else {
                setTimeout(() => {
                    if (error.successMessage === false) {
                        setIsLoading(false);
                    }
                }, 1000);
            }

        }
    }

    const loginOnKeyPress = async (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            setIsLoading(true);
            setResponseStatus('')
            let emailvalue = email;
            // eslint-disable-next-line no-useless-escape
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (cname === '') {
                setCnameError(t(`Company Name can't be blank`));
                setIsLoading(false);
                return false;
            }
            else if (name === '') {
                setNameError(t(`Name can't be blank`));
                setIsLoading(false);
                return false;
            }
            else if (!emailvalue) {
                setEmailError(t(`Email can't be blank`));
                setIsLoading(false);
                return false;
            }
            else if (!emailrex.test(String(emailvalue))) {
                setEmailError(t("Email is not valid"));
                setIsLoading(false);
                return false;
            }
            // else if (!validemail.test(String(emailvalue.toLowerCase()))) {
            //     setEmailError("Please enter only work email address");
            //     setIsLoading(false);
            //     return false;
            // }
            else if (password === "") {
                setPasswordError(t('Please enter password'));
                setIsLoading(false);
            }
            else if (coupon === "") {
                setPasswordError(t('Please enter password'));
                setIsLoading(false);
            }
            else {
                let signup = {
                    "companyName": cname,
                    "name": name,
                    "emailId": email,
                    "couponCode": coupon,
                    "password": password
                }

                const res = await dispatch(userSignupwithCoupon(signup));
                if (res?.type === 'SIGNUP_USER') {
                    await freshsales_new_contact(name, email, 'couponredeem', coupon);
                    setTimeout(() => {
                        if (error.successMessage === false) {
                            setIsLoading(false);
                        }
                    }, 1000);

                }

            }
        }
    }











    const snackClosed = () => {
        dispatch(clearErrors());
        setSuccessEnable(false);
        setSuccessMessageText('')
    }
    return (
        <Fragment>
            <div className="row bg-img" style={{ background: `url('/images/bg-img1.png') no-repeat center center fixed`, height: '100vh', width: '100%', backgroundColor: '#fff', marginLeft: window.innerWidth > 767 ? -15 : 0 }} >
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successMessage}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {successMessageText}
                    </Alert>
                </Snackbar>


                <div className='login-holder'>
                    <Fragment>
                        <img src={'/images/logo.png'} alt='' height='70px' />
                        <h2 className='forgot-btn'>{t('Welcome to GAPPEO',{ns: 'profile'})}</h2>
                        <div style={{ marginTop: 30 }}>
                            {
                                <span style={{ fontWeight: 600, color: 'red', fontSize: 14, paddingTop: '5px', position: 'relative', marginBottom: -10 }}>{responseStatus}</span>
                            }
                            <div className="form-group mt-2">
                                <div className="has-search"  >
                                    <span className="form-control-feedback" style={{ marginTop: 5 }}>
                                        <img src="/images/business.png" alt='' />
                                    </span>
                                    <input type="text" className="form-control" id="" aria-describedby="emailHelp" placeholder={t('Company Name',{ns: 'profile'})} value={cname} onChange={(e) => oncnameChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                </div>
                                <div className='error'>{cnameError}</div>
                            </div>
                            <div className="form-group mt-2">
                                <div className="has-search"  >
                                    <span className="form-control-feedback" style={{ marginTop: 5 }}>
                                        <img src="/images/user.png" alt='' width={16} />
                                    </span>
                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={t('Name',{ns: 'profile'})} value={name} onChange={(e) => onNameChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                </div>
                                <div className='error'>{nameError}</div>
                            </div>
                            <div className="form-group mt-2">
                                <div className="has-search"  >
                                    <span className="form-control-feedback" style={{ marginTop: 5 }}>
                                        <img src="/images/email.png" alt='' />
                                    </span>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={t('Enter email address',{ns: 'profile'})} value={email} onChange={(e) => onEmailChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                </div>
                                <div className='error'>{emailError}</div>
                            </div>
                            <div className="form-group">
                                <div className="has-search"  >
                                    <span className="form-control-feedback" >
                                        <img src="/images/lock.png" alt='' />
                                    </span>
                                    <input type="password" className="form-control" aria-describedby="emailHelp" value={password} placeholder={t('Password',{ns: 'profile'})} onChange={(e) => onChangePassword(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                </div>
                                <div className='error'>{passwordError}</div>

                            </div>
                            <div className="form-group mt-2">
                                <div className="has-search"  >
                                    <span className="form-control-feedback" style={{ marginTop: 5 }}>
                                        <img src="/images/gift.png" alt='' />
                                    </span>
                                    <input type="text" className="form-control" id="" aria-describedby="emailHelp" placeholder={t('Coupon code',{ns: 'profile'})} value={coupon} onChange={(e) => onCouponChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                </div>
                                <div className='error'>{couponError}</div>
                            </div>

                            <div className='position-relative'>
                                <button type='submit' onClick={() => signupUser()} disabled={isLoading} className="login-button position-relative">
                                    {isLoading ? <BallBeat
                                        color={'#fff'}
                                        loading={true}
                                    /> : "SUBMIT"}
                                </button>

                            </div>
                        </div>
                    </Fragment>
                </div>

            </div>

        </Fragment>
    )
}


export default CouponRedeem;