import React from 'react';
import './testResult.scss';
import { useTranslation } from 'react-i18next';


export default function Stepper({ type, goBack, goNext, myTest }) {

    const { t } = useTranslation();

    return (
        <div className=''>
            {
                window.innerWidth > 767 ?
                    <div className='stepper-header' style={{ display: myTest ? 'none' : 'flex' }}>
                        <img src='/images/back.svg' onClick={goBack} alt='' width={20} className='cursor-pointer' />&nbsp;&nbsp;
                        <div className='back'>
                            {t('Back To',{ns: 'home'})}<br /> {type === 'preview' ? 'Add Question' : type === 'add-question' ? 'Select tests' : 'Library'}
                        </div>
                        <div className="stepper-container">
                            <div className={type === 'test' ? "stepper-step active" : "stepper-step"}>
                                <div className="stepper-line"></div>
                                <div className="stepper-circle" >
                                </div>
                                <span className="step-label">
                                    <img src={type === 'test' ? '/images/select-test.svg' : '/images/select-test1.svg'} alt='' />
                                    &nbsp;{t('Select Test',{ns: 'home'})}
                                </span>
                            </div>

                            <div className={type === 'add-question' ? "stepper-step active" : "stepper-step"}>
                                <div className="stepper-line"></div>
                                <div className="stepper-circle" style={{ visibility: 'hidden' }}>
                                </div>
                                <span className="step-label">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10 14C9.73478 14 9.48043 14.1054 9.29289 14.2929C9.10536 14.4804 9 14.7348 9 15C9 15.2652 9.10536 15.5196 9.29289 15.7071C9.48043 15.8946 9.73478 16 10 16C10.2652 16 10.5196 15.8946 10.7071 15.7071C10.8946 15.5196 11 15.2652 11 15C11 14.7348 10.8946 14.4804 10.7071 14.2929C10.5196 14.1054 10.2652 14 10 14ZM10 4.5C9.03859 4.5 8.11656 4.88192 7.43674 5.56174C6.75692 6.24156 6.375 7.16359 6.375 8.125C6.375 8.39022 6.48036 8.64457 6.66789 8.83211C6.85543 9.01964 7.10978 9.125 7.375 9.125C7.64022 9.125 7.89457 9.01964 8.08211 8.83211C8.26964 8.64457 8.375 8.39022 8.375 8.125C8.37533 7.83004 8.45594 7.54072 8.60818 7.28809C8.76043 7.03545 8.97857 6.82902 9.2392 6.69092C9.49984 6.55282 9.79316 6.48827 10.0877 6.50419C10.3822 6.52011 10.6669 6.61589 10.9111 6.78127C11.1553 6.94666 11.35 7.1754 11.4741 7.44297C11.5982 7.71054 11.6472 8.00686 11.6157 8.30014C11.5843 8.59342 11.4736 8.87261 11.2955 9.10777C11.1175 9.34292 10.8788 9.52518 10.605 9.635C9.929 9.905 9 10.597 9 11.75V12C9 12.2652 9.10536 12.5196 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13C10.2652 13 10.5196 12.8946 10.7071 12.7071C10.8946 12.5196 11 12.2652 11 12C11 11.756 11.05 11.634 11.261 11.53L11.348 11.49C12.1288 11.1759 12.776 10.6 13.1787 9.86092C13.5814 9.12188 13.7145 8.26578 13.5551 7.43938C13.3958 6.61299 12.9539 5.86776 12.3052 5.33147C11.6566 4.79518 10.8416 4.50122 10 4.5Z" fill={type === 'add-question' ? "#ffffff" : '#878f92'} />
                                    </svg>
                                    &nbsp;{t('Add more questions',{ns: 'home'})}
                                </span>
                            </div>

                            <div className={type === 'preview' ? "stepper-step active" : "stepper-step"}>
                                <div className="stepper-line"></div>
                                <div className="stepper-circle" style={{ visibility: 'hidden' }}>
                                </div>
                                <span className="step-label">
                                    <div className='img-circle'>
                                        <img src={type === 'preview' ? '/images/add-icon-green.svg' : '/images/add-icon.png'} alt='' />
                                    </div>
                                    &nbsp;{t('Preview & Invite',{ns: 'home'})}
                                </span>
                            </div>
                        </div>
                        {type === 'preview' ? null : <div className='ms-5'>
                            <button className='next-btn' onClick={goNext}>{t('Next',{ns: 'home'})}</button>
                        </div>}
                    </div> :
                    <div className='gappeo-mobile-header1'>
                        <div className='selected-header d-flex justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/back.svg' alt='' width={15} onClick={goBack} />&nbsp;&nbsp;
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <div className={type === 'test' ? 'circle active' : 'circle'}></div>&nbsp;
                                        <div className={type === 'add-question' ? 'circle active' : 'circle'}></div>&nbsp;
                                        <div className={type === 'preview' ? 'circle active' : 'circle'}></div>
                                    </div>
                                    <h3 className='mt-2'>{type === 'test' ? 'Select Tests' : type === 'add-question' ? 'Add Question' : 'Preview & Invite'}</h3>
                                </div>
                            </div>
                            <button className='outline-btn' onClick={goNext}>{t('Next',{ns: 'home'})}</button>
                        </div>
                    </div>
            }
        </div>
    )
}
