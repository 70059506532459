import React, { Fragment } from 'react';
const ProgressBar = (props) => {

    const { bgcolor, completed, fill, labelColor, mobileWidth, margin, view, top } = props;

    const containerStyles = {
        height: 25,
        width: window.innerWidth > 767 ? 70 : mobileWidth || 90,
        backgroundColor: fill,
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        textAlign: 'right',
        borderRadius: 9
    }

    const labelStyles = {
        padding: 0,
        color: labelColor || '#222222',
        fontWeight: 'bold',
        fontSize: 24,
        marginLeft: window.innerWidth > 767 ? 75 : margin || 100,
        position: 'relative',
        top: window.innerWidth > 767 ? (top ? top : -14) : (top ? top : -28)
    }

    return (
        <Fragment>
            <div style={containerStyles}>
                <div style={fillerStyles}>
                </div>
            </div>
            <span style={labelStyles}>{`${completed}% `} {window.innerWidth > 767 ? view && `| ${view}` : null}</span>
        </Fragment>

    );
};

export default ProgressBar;