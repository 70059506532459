import React, { Fragment, useEffect, useState } from 'react';
import { setActiveTabs, getv2AdditionalQuestion, getNextv2AdditionalQuestion, getAdditionalQuestion, getNextAdditionalQuestion, setQuestionValues, deleteQuestion, getSkillsList, getSkillsTopic, getSkillsQuestion, getCodingQuestions, getNextCodingQuestions, deleteCodingQuestion, resetCodingQuestions, getQuestionLevels } from '../../Redux/ActionCreators/testLibraryAction';
import { getSkills, getSkillTopics } from '../../Redux/ActionCreators/myTestsAction';
import '../My-assessment/myAssessment.scss';
import '../My-Questions/myQuestions.scss';
import { Table } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConfirmModal from '../../utils/confirmModal';
import { ConditionalCreateQuestionButton } from './elements/popoverButtons';
import { Box } from '@mui/material';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const TABS_CONSTANTS = {
    MCQS: 'MCQS',
    CODING: 'CODING'
}

export default function MyQuestions() {
    const [page, setPage] = useState(1);
    const [codingPage, setCodingPage] = useState(1);
    const [query, setQuery] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [showSkillsTopic, setShowSkillsTopic] = useState(false);
    const [skill, setSkill] = useState('');
    const [topic, setTopic] = useState('');
    const [level, setLevel] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const details = JSON.parse(localStorage.getItem("profileDetails"));
    const [activeTab, setActiveTab] = useState(TABS_CONSTANTS.MCQS);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const rows = 20;
    const { questionLevel, active, isQuestionLoading, additionalQuestions, totalQues, skillType, skillTopic, skillQuestions, loadingCodingQuestion, total, codingQuestionListResponse } = useSelector(state => state.testLibrary);
    const { skills, topics } = useSelector(state => state.myTests);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        setCodingPage(1);
        setPage(1);
        if (active === 'MCQS') {
            setActiveTab('MCQS')
            { (details?.tenentId === "-1" && details?.roleId === "1") && dispatch(getSkillsList(0)) };
            ((details?.tenentId !== "-1" && details?.roleId !== "1") && dispatch(getSkills(2)));
            dispatch(getQuestionLevels());
            dispatch(getv2AdditionalQuestion('', skill?.value || 0, topic?.value || 0, level?.value || 0, 1, page, rows));
            // dispatch(getAdditionalQuestion(1, '', 1, rows, selectedskillId, selectedskillTopicId));
        } else {

            setActiveTab('CODING')
            dispatch(getCodingQuestions(1, 20, 0, 0, '', []));
        }
    }, []);

    const handleTabChange = async (tab_name) => {
        await dispatch(setActiveTabs(tab_name));
        await setPage(1);
        await setCodingPage(1);
        await setSkill('');
        await setTopic('')
        if (tab_name === TABS_CONSTANTS.MCQS) {
            await dispatch(getv2AdditionalQuestion('', skill?.value || 0, topic?.value || 0, level?.value || 0, 1, 1, rows));
            setActiveTab(tab_name);
        }
        if (tab_name === TABS_CONSTANTS.CODING) {
            await dispatch(resetCodingQuestions())
            await dispatch(getCodingQuestions(1, 20, 0, 0, '', []));
            setActiveTab(tab_name);
        }
    };

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextv2AdditionalQuestion('', skill?.value || 0, topic?.value || 0, level?.value || 0, 1, page + 1, rows));
    }

    const paginateCodingQuestions = () => {
        setCodingPage(codingPage + 1);
        dispatch(getNextCodingQuestions(codingPage + 1, rows, 0, 0, query));
    }

    const timerRef1 = React.useRef(null);

    const searchQuestion = (val) => {
        clearTimeout(timerRef1.current);
        setPage(1);
        setQuery(val);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        if (activeTab === TABS_CONSTANTS.CODING) {
            await dispatch(getCodingQuestions(1, 20, 0, 0, val, []));
        } else {
            dispatch(getv2AdditionalQuestion(val, skill?.value || 0, topic?.value || 0, level?.value || 0, 1, page, rows));
            // await dispatch(getAdditionalQuestion(1, val, page, rows, selectedskillId, selectedskillTopicId));
        }
    }

    const openCreate = async () => {
        await dispatch(setQuestionValues({ question: null }));
        navigate('/create', { state: { edit: false, question: true, detail: null, active } });
    }

    const editQuestion = async (el, view) => {
        await dispatch(setQuestionValues(el));
        navigate('/create', { state: { detail: el, edit: true, question: true, view: view } })
    }

    const viewEditCodingQuestion = async (el, view) => {
        await dispatch(setQuestionValues(el));
        navigate('/create-coding-question', { state: { detail: el, edit: true, question: true, view: view } })
    }

    const deleteQues = (id) => {
        setDeleteId(id);
        setIsConfirm(true);
    }


    const removeConfirm = async () => {
        let obj = {
            questionID: deleteId
        }
        const res = await dispatch(deleteQuestion(obj));
        if (res?.type === 'DELETE_QUESTION') {
            setIsConfirm(false);
            setDeleteId('');
        }
    }
    const removeCodingQuestionOnConfirm = async () => {
        let req_obj = {
            id: deleteId
        }
        const res = await dispatch(deleteCodingQuestion(req_obj));
        if (res?.type === 'DELETE_QUESTION') {
            setIsConfirm(false);
            setDeleteId('');
            setCodingPage(1);
            dispatch(getCodingQuestions(1, rows, 0, 0, query, []));
        }
    }

    const onDeleteQuestionConfirmation = () => {
        if (activeTab === TABS_CONSTANTS.MCQS) {
            removeConfirm();
        }
        if (activeTab === TABS_CONSTANTS.CODING) {
            removeCodingQuestionOnConfirm();
        }
    }

    // * handle skill change
    const handleSkillChange = async (e) => {
        setLoading(true);
        setSkill(e)
        await dispatch(getv2AdditionalQuestion('', e?.value || 0, topic?.value || 0, level?.value || 0, 1, 1, rows));
        try {
            const response = (details?.tenentId === "-1" && details?.roleId === "1") ? await dispatch(getSkillsTopic(e.value)) : await dispatch(getSkillTopics(2, e.value));
            // console.log("🚀 ~ handleSkillChange ~ response:", response)
            console.log(response)
            if (response.payload) {
                setLoading(false);
                setShowSkillsTopic(true);
                setTopic("");
            }
            else {
                console.log("\n Failed ", response);
                setLoading(false)
            }
        } catch (error) {
            console.error((t('Error updating subscription:')) , error);
            setLoading(false)
        }
        console.log(showSkillsTopic)
    };

    // * handle skill topic change
    const handleSkillTopicChange = async (e) => {
        setTopic(e)
        dispatch(getv2AdditionalQuestion('', skill?.value || 0, e?.value || 0, level?.value || 0, 1, page, rows));
        // await dispatch(getAdditionalQuestion(1, '', page, rows, selectedskillId, selectedId));
    };

    const handleLevel = async (e) => {
        setLevel(e)
        dispatch(getv2AdditionalQuestion('', skill?.value || 0, topic?.value || 0, e?.value || 0, 1, page, rows));
        // await dispatch(getAdditionalQuestion(1, '', page, rows, selectedskillId, selectedId));
    };


    return (
        <div className='gappeo-my-tests'>
            <h1 className='text-center'>{t('My Questions',{ns: 'mytest'})}</h1>
            {details?.tenentId === "-1" && details?.roleId === "1" ?
                <div className='report-btns d-flex align-items-center'>
                    <button className={activeTab === TABS_CONSTANTS.MCQS ? 'active' : ''} onClick={() => handleTabChange(TABS_CONSTANTS.MCQS)}>MCQs</button>&nbsp;&nbsp;
                    <button className={activeTab === TABS_CONSTANTS.CODING ? 'active' : ''} onClick={() => handleTabChange(TABS_CONSTANTS.CODING)}>{t('Coding Questions',{ns: 'mytest'})}</button>&nbsp;
                </div> :
                null
            }
            <div className='my-search mt-2'>
                {(details?.tenentId === "-1" && details?.roleId === "1" && activeTab === 'MCQS') ? (
                    <div className="row">
                        <div className="col-md-6 col-12 select-wrapper">
                            <Select
                                options={skillType?.map(el => [{ label: el.skillName, value: el.skillId }][0])}
                                value={skill}
                                placeholder={t('Select Knowledge Area',{ns: 'mytest'})}
                                onChange={(e) => handleSkillChange(e)}
                            />

                        </div>
                        {/* {console.log('showSkillsTopic ', showSkillsTopic, " skillTopic ", skillTopic)} */}
                        {(showSkillsTopic && skillTopic?.length > 0) ?
                            <div className="col-md-6 col-12 select-wrapper">
                                <Select
                                    options={skillTopic?.map(el => [{ label: el.skillTopicName, value: el.skillTopicId }][0])}
                                    value={topic}
                                    placeholder={t('Select Topic',{ns: 'mytest'})}
                                    onChange={(e) => handleSkillTopicChange(e)}
                                />
                            </div> : null
                        }

                    </div>
                ) :
                    activeTab === 'MCQS' ?
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='mt-2'>
                                    <Select
                                        isClearable
                                        options={skills}
                                        value={skill}
                                        placeholder={t('Select Knowledge Area',{ns: 'mytest'})}
                                        onChange={(e) => handleSkillChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='mt-2'>
                                    <Select
                                        isClearable
                                        options={topics}
                                        value={topic}
                                        placeholder={t('Select Topic',{ns: 'mytest'})}
                                        onChange={(e) => handleSkillTopicChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='mt-2'>
                                    <Select
                                        isClearable
                                        options={questionLevel?.map(el => [{ label: el.questionLevelName, value: el.questionLevelID }][0])}
                                        value={level}
                                        placeholder={t('Select Level',{ns: 'mytest'})}
                                        onChange={(e) => handleLevel(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }

                <div className='search-test  mt-3 flex-wrap d-flex justify-content-between gap-1' style={{ alignItems: window.innerWidth < 767 ? 'flex-start' : 'center', flexDirection: window.innerWidth < 767 && 'column-reverse' }}>
                    <div className="has-search" style={{ width: window.innerWidth > 767 ? '79%' : '100%' }} >
                        <span className="form-control-feedback" >
                            <img src="/images/search.svg" alt='' width={12} />
                        </span>
                        <input type="text" className="form-control" value={query} placeholder={t('Search for Question',{ns: 'mytest'})} onChange={(e) => searchQuestion(e.target.value)} />
                    </div>
                    <div style={{ width: window.innerWidth > 767 ? '20%' : '50%' }}>
                        {details?.tenentId === "-1" && details?.roleId === "1" ?
                            <ConditionalCreateQuestionButton openCreate={openCreate} navigate={navigate} /> :
                            <button style={{ width: '-webkit-fill-available' }} onClick={() => openCreate()}>{t('Create Question',{ns: 'mytest'})}</button>
                        }
                    </div>
                </div>

            </div>


            {activeTab === TABS_CONSTANTS.MCQS &&
                <div className="table-admin">
                    <InfiniteScroll
                        dataLength={additionalQuestions && additionalQuestions?.length}
                        next={totalQues > additionalQuestions?.length ? fetchData : ''}
                        hasMore={true}
                    >
                        {
                            isQuestionLoading ?
                            t('Loading..',{ns: 'home'}) :
                                additionalQuestions?.length > 0 ?
                                    <Table responsive>
                                        <thead>
                                            <th className='text-left'>{t('Question',{ns: 'mytest'})} </th>
                                            <th className='text-left'>{t('Type',{ns: 'mytest'})}</th>
                                            <th className='text-left'>{t('Knowledge Area',{ns: 'mytest'})}</th>
                                            <th className='text-left'>{t('Topic/Tags',{ns: 'mytest'})}</th>
                                            <th className='text-left'>{t('Action',{ns: 'mytest'})}</th>
                                        </thead>
                                        <tbody className="table-body">
                                            {
                                                additionalQuestions && additionalQuestions?.map(el => {
                                                    return (
                                                        <tr>
                                                            {
                                                                window.innerWidth > 767 ?
                                                                    <Fragment>
                                                                        <td style={{ width: '35%' }}>
                                                                            <h5 className='elipsis-2' dangerouslySetInnerHTML={{ __html: el?.question }} />
                                                                        </td>
                                                                        <td style={{ width: '16%', textAlign: 'left' }}>
                                                                            <div>
                                                                                {el?.questionTypeName === 'Multiple Choice' ?
                                                                                    <img src='/images/mcq.svg' alt='' width={20} /> :
                                                                                    <img src='/images/essay.svg' alt='' width={20} />
                                                                                }
                                                                                <span> {el?.questionTypeName}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ width: '20%', textAlign: 'left' }}>
                                                                            <div>
                                                                                {el?.skillName}
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ width: '20%', textAlign: 'left' }}>
                                                                            <div>
                                                                                {el?.skillTopicName}
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ width: 100 }}>
                                                                            <img src='/images/view-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => editQuestion(el, true)} />&nbsp;&nbsp;
                                                                            <img src='/images/edit-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => editQuestion(el, false)} />&nbsp;&nbsp;
                                                                            <img src='/images/delete-color-icon.svg' width={16} height={16} className='cursor-pointer' onClick={() => deleteQues(el.questionID)} alt='' />&nbsp;&nbsp;
                                                                        </td>
                                                                    </Fragment> :
                                                                    <Fragment>
                                                                        <td style={{ width: '75%' }}>
                                                                            <h5 className='elipsis-2' dangerouslySetInnerHTML={{ __html: el?.question }} />
                                                                            <div className='d-flex justify-content-start mt-2'>
                                                                                {el?.questionTypeName === 'Multiple Choice' ?
                                                                                    <img src='/images/mcq.svg' alt='' width={20} /> :
                                                                                    <img src='/images/essay.svg' alt='' width={20} />
                                                                                }
                                                                                <span> {el?.questionTypeName}</span>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <img src='/images/view-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => editQuestion(el, true)} />&nbsp;&nbsp;
                                                                            <img src='/images/edit-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => editQuestion(el, false)} />&nbsp;&nbsp;
                                                                            <img src='/images/delete-color-icon.svg' width={16} height={16} className='cursor-pointer' onClick={() => deleteQues(el.questionID)} alt='' />&nbsp;&nbsp;
                                                                        </td>
                                                                    </Fragment>
                                                            }


                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table> :
                                    <div className='empty-list'>
                                        <img src='/images/magnifier.svg' alt='' />
                                        <div className='mt-2'>
                                            <h3>{t('No Questions added',{ns: 'mytest'})}</h3>
                                        </div>
                                    </div>
                        }
                    </InfiniteScroll>
                </div>}
            {activeTab === TABS_CONSTANTS.CODING &&
                <div className="table-admin">
                    <InfiniteScroll
                        dataLength={codingQuestionListResponse && codingQuestionListResponse?.length || 0}
                        next={total > codingQuestionListResponse?.length ? paginateCodingQuestions : ''}
                        hasMore={true}
                    >
                        {
                            loadingCodingQuestion ?
                            t('Loading..',{ns: 'home'}) :
                                codingQuestionListResponse?.length > 0 ?
                                    <Table responsive>
                                        <tbody className="table-body">
                                            {
                                                codingQuestionListResponse && codingQuestionListResponse?.map(coding_question => {
                                                    return (
                                                        <tr>
                                                            {
                                                                window.innerWidth > 767 ?
                                                                    <Fragment>
                                                                        <td style={{ width: '55%' }}>
                                                                            <h5 className='elipsis-2'>{coding_question?.name}</h5>
                                                                        </td>
                                                                        <td style={{ textAlign: 'left' }}>
                                                                            <div>
                                                                                {coding_question?.questionTypeName === 'Multiple Choice' ?
                                                                                    <img src='/images/mcq.svg' alt='' width={20} /> :
                                                                                    <img src='/images/essay.svg' alt='' width={20} />
                                                                                }
                                                                                <span> {coding_question?.difficultyLevelName}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <img src='/images/view-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => viewEditCodingQuestion(coding_question, true)} />&nbsp;&nbsp;
                                                                            <img src='/images/edit-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => viewEditCodingQuestion(coding_question, false)} />&nbsp;&nbsp;
                                                                            <img src='/images/delete-color-icon.svg' width={16} height={16} className='cursor-pointer' onClick={() => deleteQues(coding_question?.id)} alt='' />&nbsp;&nbsp;
                                                                        </td>
                                                                    </Fragment> :
                                                                    <Fragment>
                                                                        <td style={{ width: '75%' }}>
                                                                            <h5 className='elipsis-2'>{coding_question?.name}</h5>
                                                                            <div className='d-flex justify-content-start mt-2'>
                                                                                {coding_question?.questionTypeName === 'Multiple Choice' ?
                                                                                    <img src='/images/mcq.svg' alt='' width={20} /> :
                                                                                    <img src='/images/essay.svg' alt='' width={20} />
                                                                                }
                                                                                <span> {coding_question?.difficultyLevelName}</span>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <img src='/images/view-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => viewEditCodingQuestion(coding_question, true)} />&nbsp;&nbsp;
                                                                            <img src='/images/edit-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => viewEditCodingQuestion(coding_question, false)} />&nbsp;&nbsp;
                                                                            <img src='/images/delete-color-icon.svg' width={16} height={16} className='cursor-pointer' onClick={() => deleteQues(coding_question?.id)} alt='' />&nbsp;&nbsp;
                                                                        </td>
                                                                    </Fragment>
                                                            }


                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table> :
                                    <Box
                                        height='50vh'
                                        my={4}
                                        display="flex"
                                        flexDirection={"column"}
                                        alignItems="center"
                                        justifyContent="center"
                                        gap={4}
                                        p={2}
                                        sx={{ border: '2px solid grey' }}
                                    >
                                        <img src='/images/magnifier.svg' alt='' />
                                        <div className='mt-2'>
                                            <h3>{t('Not found any coding question',{ns: 'mytest'})}</h3>
                                        </div>
                                    </Box>
                        }
                    </InfiniteScroll>
                </div>
            }
            <ConfirmModal
                isConfirm={isConfirm}
                setIsConfirm={() => setIsConfirm(false)}
                removeConfirm={onDeleteQuestionConfirmation}
            />

            {loading && (
                <div className="gp-loader-container">
                    <div className="gp-loader"></div>
                </div>
            )}
        </div>
    )
}


