import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getTests, testRegister, } from '../../Redux/ActionCreators/candidateTestAction';
import { addDays } from '../../utils/miscelleneous';
import { useTranslation } from 'react-i18next';

export default function RegisterTest() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [btnloading, setBtnLoading] = useState(false);
    const [isRegister, setIsRegister] = useState(false);

    const dispatch = useDispatch();

    const { testDetails } = useSelector(state => state.candidateTest);

    useEffect(() => {
        if (window.location.pathname.split('/')[1] === 'candidate-invite-test' && window.location.pathname.split('/')[2]) {
            const id = atob(window.location.pathname.split('/')[2]);
            dispatch(getTests(id.split('|')[0]));
            setLoading(false);
        }
    }, []);


    const submitDetail = async () => {
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ((name === '')) {
            setNameError(t(`Name can't empty`))
        }
        else if ((email === '')) {
            setEmail(t(`Email can't empty`))
        }
        else if (!emailrex.test(String(email))) {
            setEmailError(t('Email is not valid'));
        }
        else {
            await setBtnLoading(true);
            const id = atob(window.location.pathname.split('/')[2]);
            let obj = {
                "name": name,
                "emailId": email,
                "testId": Number(id.split('|')[0]),
                "testURL": window.location.protocol + '//' + window.location.host + `/candidate-test/token`,
                "validTill": addDays(new Date(), 2),
                "userId": Number(id.split('|')[1])
            }

            const res = await dispatch(testRegister(obj));
            if (res?.type === 'TEST_REGISTER') {
                setIsRegister(true);
                setBtnLoading(false);
                setLoading(false);
            }
        }

    }

    return (
        <Fragment>
            {loading ?
                t('Loading..',{ns: 'home'})
                :
                (!isRegister ?
                    <div className='candidate-test-holder'>
                        <div className='candidate-banner'>
                            <img src='/images/banner.png' alt='' width={'100%'} />
                        </div>
                        <div className='test-summary'>
                            {window.innerWidth > 767 && <h3>{t('Assessment Summary')}</h3>}
                            <div className='d-flex mt-3'>
                                {window.innerWidth > 767 &&
                                    <div className='test-list'>
                                        {testDetails?.testDetails?.tests.map(el => {
                                            return (
                                                <div className='list-item'>
                                                    <div>
                                                        <h4>{el.testName}</h4>
                                                        <p>{el.questionCount} {t('Questions')} {el.duration} {t('Mins')}</p>
                                                    </div>
                                                    {el.checked ?
                                                        <img src='/images/tick-mark.svg' alt='' width={18} />
                                                        :
                                                        <img src='/images/grey-tick-mark.svg' alt='' />
                                                    }

                                                </div>
                                            )
                                        })}
                                        {
                                            testDetails?.testDetails?.questions?.length > 0 &&
                                            <div className='list-item'>
                                                <div>
                                                    <h4>{t('Additional Questions')}</h4>
                                                    <p>{testDetails?.testDetails?.questions?.length} {t('Questions')}</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {window.innerWidth > 767 && <div style={{ borderLeft: '1px solid #D7DADB', margin: '0px 75px', minHeight: 450 }}></div>}
                                <div className='test-list' style={{ width: window.innerWidth > 767 ? '32%' : '100%' }}>
                                    <h2>{t('Hi')} </h2>
                                    <p>{t('Welcome to the assessment for')} {testDetails?.testName}.
                                        {t('Please enter your name and email to continue')}</p>
                                    {
                                        window.innerWidth < 767 &&
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        {t('Assessment Summary')}
                                                        <p>{testDetails?.testDetails?.test?.length} {t('Tests')}, {testDetails?.questionCount} {t('Question')}, {testDetails?.duration} {t('minutes')}</p>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className={`accordion-collapse collapse show`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <div className='test-list'>
                                                            {testDetails?.testDetails?.tests.map(el => {
                                                                return (
                                                                    <div className='list-item'>
                                                                        <div>
                                                                            <h4>{el.testName}</h4>
                                                                            <p>{el.questionCount} {t('Questions')}{el.duration} {t('Mins')}</p>
                                                                        </div>
                                                                        {el.checked ?
                                                                            <img src='/images/tick-mark.svg' alt='' width={18} />
                                                                            :
                                                                            <img src='/images/grey-tick-mark.svg' alt='' />
                                                                        }

                                                                    </div>
                                                                )
                                                            })}
                                                            {
                                                                testDetails?.testDetails?.questions?.length > 0 &&
                                                                <div className='list-item'>
                                                                    <div>
                                                                        <h4>{t('Additional Questions')}</h4>
                                                                        <p>{testDetails?.testDetails?.questions?.length}  {t('Questions')}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className='mt-2' >
                                        <div className='form-group'>
                                            <input type='text' placeholder='Name' className='form-control' onChange={(e) => {
                                                setName(e.target.value)
                                                setNameError('')
                                            }} />
                                            <small style={{ color: 'red' }}>{nameError}</small>
                                        </div>
                                        <div className='form-group'>
                                            <input type='email' placeholder='Email' className='form-control' onChange={(e) => {
                                                setEmail(e.target.value)
                                                setEmailError('')
                                            }} />
                                            <small style={{ color: 'red' }}>{emailError}</small>
                                        </div>
                                        <button className='form-control mt-2' style={{ background: "#009199", color: "#fff" }} disabled={btnloading} onClick={() => submitDetail()}>{t('CONTINUE')}</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div> :
                    <div className='candidate-test-holder'>
                        <div className='thank-screen'>
                            <img src='/images/thankyouimg.jpg' alt='' />
                            <h3>{t('Thank you for registering for the test! The test link has been sent to your email.')}</h3>
                        </div>
                    </div>
                )
            }
        </Fragment>

    )
}
