import { GET_MY_ASSESSMENTS, GET_NEXT_MY_ASSESSMENTS, NO_TESTS, GET_REPORT, GET_NEXT_REPORT, RESET_TEST_LOADER, GET_DETAILED_REPORT } from "../Types/types";

let intialState = {
    myAssessments: [],
    isTestLoading: true
}


export default function myAssessmentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_MY_ASSESSMENTS:
            stateCopy.myAssessments = action.payload.testList;
            stateCopy.total = action.payload.totalCount;
            stateCopy.isTestLoading = false;
            return stateCopy;
        case GET_NEXT_MY_ASSESSMENTS:
            stateCopy.myAssessments = stateCopy.myAssessments.concat(action.payload.testList);
            stateCopy.total = action.payload.totalCount;
            stateCopy.isTestLoading = false;
            return stateCopy;
        case NO_TESTS:
            stateCopy.myAssessments = [];
            stateCopy.isTestLoading = false;
            return stateCopy;
        case RESET_TEST_LOADER:
            stateCopy.isTestLoading = true;
            return stateCopy;
        case GET_REPORT:
            stateCopy.testReport = action.payload.candidateReportList;
            return stateCopy;
        case GET_NEXT_REPORT:
            stateCopy.testReport = stateCopy.testReport.concat(action.payload.candidateReportList);
            return stateCopy;
        case GET_DETAILED_REPORT:
            stateCopy.detailReport = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}