import './loader.scss';


export const BallBeat = (props) => {
    return (
        <div className="ball-loader" >
            <div className="ball-loader-ball ball1" style={{ backgroundColor: props.color }}></div>
            <div className="ball-loader-ball ball2" style={{ backgroundColor: props.color }}></div>
            <div className="ball-loader-ball ball3" style={{ backgroundColor: props.color }}></div>
        </div>
    )

}

export const ThreeDots = (props) => {
    return (
        <div className="three-dots">
            <div className="loader" id="loader-4">
                <span style={{ background: props.color || '#009199' }}></span>
                <span style={{ background: props.color || '#009199' }}></span>
                <span style={{ background: props.color || '#009199' }}></span>
            </div>
        </div>
    )
}