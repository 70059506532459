import React, { useEffect, useState } from 'react';
import '../Users/users';
import { Table } from 'react-bootstrap';
import { convertDate } from '../../utils/miscelleneous';
import Switch from 'react-switch';
import { useDispatch } from 'react-redux';
import { getUsers, getNextUsers, resetUserList, addUser, changeStatus } from '../../Redux/ActionCreators/userAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { BallBeat } from '../../Loader/loader';
import { useTranslation } from 'react-i18next';


export default function TanentUsers() {
    const [active, setActive] = useState('users');
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1)
    const [isAddUser, setIsAddUser] = useState(false);
    const [name, setName] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const { t } = useTranslation();
    const [nameError, setNameError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const rows = 20;
    const dispatch = useDispatch();

    const { isUserLoading, usersList, total } = useSelector(state => state.users);

    useEffect(() => {
        dispatch(getUsers(query, 1, rows));
    }, [])

    const changeTab = (e) => {
        setActive(e);
    }
    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextUsers(query, page + 1, rows))
    }
    const timerRef1 = React.useRef(null);


    const searchUser = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        await dispatch(resetUserList());
        await dispatch(getUsers(val, page, rows));
    }

    const handleChange = (el, active) => {
        let obj = {
            userId: el?.userID,
            statusId: active === 0 ? 1 : 0
        }
        dispatch(changeStatus(obj))
    }

    const changeName = (val) => {
        setName(val);
        setNameError('');
    }

    const changeEmail = (val) => {
        setEmail(val);
        setEmailError('');
    }

    const saveUser = async () => {
        if (name === '') {
            setNameError(t(`Name can't empty`))
        }
        else if (email === '') {
            setEmailError(t(`Email can't empty`))
        }
        else {
            setisLoading(true);
            const obj = {
                name: name,
                emailId: email
            }
            const res = await dispatch(addUser(obj));
            if (res?.type === 'ADD_USERS') {
                setIsAddUser(false);
                dispatch(getUsers('', 1, rows));
            }
            setisLoading(false);
        }
    }

    const handleModal = () => {
        setName('')
        setNameError('');
        setEmail('');
        setEmailError('');
        setIsAddUser(false);
    }





    return (
        <div className='users-holder'>
            <div className='users-header'>
                <h2>{t('Users and Lists',{ns: 'profile'})}</h2>
                {/* <div className='d-flex justify-content-center mt-2'>
                    <button className={active === 'users' ? 'active' : ''} onClick={() => changeTab('users')}>USERS</button>
                    <button className={active === 'lists' ? 'active' : ''} onClick={() => changeTab('lists')}>LISTS</button>
                </div> */}
                <div className='d-flex align-items-center flex-wrap'>
                    <div className="has-search"  >
                        <span className="form-control-feedback" >
                            <img src="/images/search.svg" alt='' width={12} />
                        </span>
                        <input type="text" className="form-control" value={query} placeholder={('Search for a user',{ns: 'profile'})}
                            onChange={(e) => searchUser(e.target.value)}
                        />
                    </div>&nbsp;&nbsp;
                    <button className='add-btn' onClick={() => setIsAddUser(true)}>{t('ADD USERS',{ns: 'profile'})}</button>
                </div>
            </div>
            <div className="table-admin">
                <InfiniteScroll
                    dataLength={usersList && usersList?.length} //This is important field to render the next data
                    next={total > usersList?.length ? fetchData : ''}
                    hasMore={true}
                >
                    <Table responsive>
                        <thead>
                            <th>{t('Name',{ns: 'profile'})}</th>
                            <th>{t('Email',{ns: 'profile'})}</th>
                            <th>{t('Role',{ns: 'profile'})}</th>
                            <th>{t('Joined',{ns: 'profile'})}</th>
                            <th>{t('Status',{ns: 'profile'})}</th>
                            {/* <th>Action</th> */}
                        </thead>
                        <tbody className="table-body">
                            {isUserLoading ?
                                'Loading' :
                                usersList && usersList?.map(el => {
                                    return (
                                        <tr>
                                            <td>
                                                {el.name}
                                            </td>
                                            <td>
                                                {el.emailId}
                                            </td>
                                            <td>
                                                {el.roleName}
                                            </td>
                                            <td>
                                                {convertDate(new Date())}
                                            </td>
                                            <td>
                                                <p onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    handleChange(el, el.isActive)
                                                }}> <Switch
                                                        onChange={(e) => { console.log(e) }}
                                                        width={30}
                                                        height={16}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        onColor='#02c573'
                                                        checked={el.isActive === 1 ? true : false} />&nbsp;&nbsp;<br />
                                                    {el.isActive === 1 ? 'Active' : 'Inactive'}</p>
                                            </td>
                                            {/* <td>
                                                <img src='/images/info.svg' alt='' />&nbsp;&nbsp;
                                                <img src='/images/add-icon.svg' width={20} height={20} alt='' />
                                            </td> */}

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </InfiniteScroll>
            </div>

            {/* ############## add and edit user modal ############### */}
            <Modal
                show={isAddUser}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-user-modal"}
            >
                <div class="modal-header" style={{ background: '#009199' }}>
                    <h5 class="modal-title">{'Add User'}  </h5>

                    <div className="sticky-close" onClick={handleModal}>
                        <img src="/images/close-white.svg" width='15px' alt='' /></div>

                </div>
                <Modal.Body style={{ padding: '22px 40px 22px 40px', }}>

                    <div>
                        <label>{t('Name',{ns: 'profile'})}*
                        </label>
                        <input className='form-control mt-2' value={name} onChange={(e) => changeName(e.target.value)} />
                    </div>
                    <small>{nameError}</small>
                    <div className='mt-2' >
                        <label>
                            {t('Email',{ns: 'profile'})}*
                        </label>
                        <input className='form-control mt-2' value={email} onChange={(e) => changeEmail(e.target.value)} />
                    </div>
                    <small>{emailError}</small>

                    <div className='save-btn'>
                        <button disabled={isLoading} onClick={saveUser}>
                            {isLoading ? <div className='ballbeat'><BallBeat color="#fff" /></div> : 'Save'}</button>
                        <button onClick={() => handleModal()}>{t('Cancel',{ns: 'profile'})}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
