import React, { useEffect, useState } from 'react';
import './myAssessment.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getReport, getNextReport, resetLoading } from '../../Redux/ActionCreators/reportAction';
import { resetTestLoader } from '../../Redux/ActionCreators/myAssessmentAction';
import { convertDate, formatAMPM } from '../../utils/miscelleneous';
import { Table } from 'react-bootstrap';
import exportFromJSON from "export-from-json";
import { Dialog } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function AssessmentReport() {
    const [sortVal, setSortVal] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [search, setSearch] = useState('');
    const [loadData, setLoadData] = useState(false);
    const [testId, setTestId] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const rows = 12;
    const { testReport, totalCount, isReportLoading } = useSelector(state => state.report);
    const { t } = useTranslation();
    const timerRef2 = React.useRef(null);

    useEffect(() => {
        const firstRender = async () => {
            setTestId(location.state.id);
            await dispatch(resetLoading());
            const res = await dispatch(getReport(location.state.id, '', 1, 12, sortVal));
            if (res?.type === 'GET_REPORT') {

            }
        }
        firstRender();
    }, [])

    const selectSort = async (val) => {
        setSortVal(val);
        setPageCount(1);
        await dispatch(getReport(testId, val, 1, 12, val));
    }

    const fetchNextReportData = () => {
        setPageCount(pageCount + 1);
        dispatch(getNextReport(testId, '', pageCount + 1, rows, sortVal));
    }

    const searchName = (val) => {
        clearTimeout(timerRef2.current);
        setSearch(val);
        setPageCount(1);
        timerRef2.current = setTimeout(() => delaySearch(val), 800);
    }


    const delaySearch = async (val) => {
        await dispatch(resetTestLoader());
        await dispatch(getReport(testId, val, pageCount, 12, sortVal));
    }


    const openOneReport = (id) => {
        navigate('/test-report', { state: { id: id, type: 'new', myTest: true, path: 'assessment' } })
    }

    const downloadData = async () => {
        await setLoadData(true);
        const res = await dispatch(getReport(testId, '', 1, -1, sortVal));
        if (res?.type === 'GET_REPORT') {
            const data = res.payload?.candidateReportList.map(
                (el) => [
                    {
                        'Name': el.name ? el.name : '',
                        'Email': el.emailId,
                        'Test': el.testName,
                        'Test Attempt Date': el.testAttemptDate ? convertDate(el.testAttemptDate) : '',
                        'Score': el.score,
                    }
                ][0]
            );
            const fileName = "report_data";
            const exportType = exportFromJSON.types.csv;
            exportFromJSON({ data, fileName, exportType });
        }

        await setLoadData(false);
    }
    return (
        <div className='gappeo-my-tests'>
            <Dialog
                className='screen-loader'
                open={loadData}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {t('Loading', { ns: 'mytest' })}...
            </Dialog>
            <h1 className='d-flex justify-content-between flex-wrap' style={{ paddingLeft: window.innerWidth > 767 ? '' : 15, width: window.innerWidth > 767 ? '100%' : '' }}>
                <img src='/images/back.svg' alt='' className='cursor-pointer' onClick={() => navigate(-1)} />
                {t('Assessment Report', { ns: 'mytest' })}
                <div style={{ fontSize: 16, color: '#009199', cursor: 'pointer' }} onClick={() => downloadData()}>
                    <img src='/images/download.svg' alt='' width={20} />&nbsp;&nbsp;
                    {t('Download', { ns: 'mytest' })}
                </div>
            </h1>
            <div className='search-test mt-3 d-flex align-items-end'>
                <div className="has-search"  >
                    <span className="form-control-feedback" >
                        <img src="/images/search.svg" alt='' width={12} />
                    </span>
                    <input type="text" className="form-control" value={search} placeholder={t('Search candidate by Name or Email Address', { ns: 'mytest' })} onChange={(e) => searchName(e.target.value)} />
                </div>
                <div style={{ width: '30%' }} className='ms-2'>
                    <p>{t('Sort by', { ns: 'mytest' })}</p>
                    <select className='form-control' value={sortVal} onChange={(e) => selectSort(e.target.value)}  >
                        <option value={1}>{t('Score', { ns: 'mytest' })}</option>
                        <option value={2}>{t('Assessment Attempt Date', { ns: 'mytest' })}</option>
                    </select>
                </div>
            </div>
            <div className="table-admin mt-3">
                <InfiniteScroll
                    dataLength={testReport && testReport?.length} //This is important field to render the next data
                    next={totalCount > testReport?.length ? fetchNextReportData : ''}
                    hasMore={true}
                >
                    {isReportLoading ?
                        t('Loading..', { ns: 'home' })
                        :
                        testReport?.length > 0 ?
                            <Table responsive>
                                <thead>
                                    <th>{t('Name', { ns: 'mytest' })}</th>
                                    <th>{t('Email', { ns: 'mytest' })}</th>
                                    <th>{t('Assessment', { ns: 'mytest' })}</th>
                                    <th>{t('Assessment Attempt date', { ns: 'mytest' })}</th>
                                    <th>{t('Score', { ns: 'mytest' })}</th>
                                    <th>{t('View', { ns: 'mytest' })}</th>
                                </thead>
                                <tbody className="table-body">
                                    {
                                        testReport && testReport?.map((el) => {
                                            return (
                                                <tr>
                                                    <td>{el.name}</td>
                                                    <td>{el.emailId}</td>
                                                    <td>{el.testName}</td>
                                                    <td>{el.testAttemptDate ? convertDate(el.testAttemptDate) : ''}<br />
                                                        {el.testAttemptDate ? formatAMPM(moment.utc(el.testAttemptDate).local().format()) : ''}
                                                    </td>
                                                    <td>{el.status === 3 ? el.score : 'In Progress'}</td>
                                                    <td >
                                                        {el.status === 3 ?
                                                            <img src='/images/view-icon.svg' alt='' className='cursor-pointer' onClick={() => openOneReport(el.inviteId)} /> :
                                                            'In Progress'
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table> :
                            <div className='empty-list'>
                                <img src='/images/magnifier.svg' alt='' />
                                <div className='mt-2'>
                                    <h3>{t('There is no Test report yet', { ns: 'mytest' })}</h3>
                                </div>
                            </div>
                    }
                </InfiniteScroll>
            </div>
        </div>
    )
}
