import React, { useEffect, useState } from 'react';
import './settings.scss';
import { useDispatch } from 'react-redux';
import { applyCoupon, getPlans, getAccountDetail, validateCoupon } from '../../Redux/ActionCreators/settingAction';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


export default function Settings() {
    const [coupon, setCoupon] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { plans, accountDetail } = useSelector(state => state.setting);

    useEffect(() => {
        dispatch(getAccountDetail());
        dispatch(getPlans());
    }, [])

    const changeCoupon = (val) => {
        setCoupon(val);
    }

    const apply = async () => {
        const res = await dispatch(validateCoupon(coupon));
        if (res?.type === 'VALIDATE_COUPON_CODE') {
            await dispatch(applyCoupon(coupon));
            await dispatch(getAccountDetail());
            await dispatch(getPlans());
            setCoupon('');
        }
    }

    const openPayment = () => {
        window.open('https://www.paypal.com/ncp/payment/Q9NMR5PARS5NG', '_blank')
    }

    return (
        <div className='settings-holder'>
            <div className="bg-white payment-plans-main rounded mt-3 mb-3 px-5 py-3">
                <div className="row">
                    {window.innerWidth > 767 &&
                        <div className="col-3">

                        </div>}
                    <div className={window.innerWidth > 767 ? "col-md-6" : "col-12"}>
                        <h1 className="maintxt">{t('Payment Plans Details', { ns: 'profile' })} </h1>
                    </div>

                </div>
                <div className="row py-3">
                    <div className="col-3">
                        <span className="labletext px-0">{t('Plan', { ns: 'profile' })} :</span> <span className="labletext px-0"> {accountDetail?.planName}</span>
                    </div>
                    <div className="col-3">
                        <span className="labletext px-0">{t('Plan Test Credits', { ns: 'profile' })} :</span> <span className="labletext px-0">{accountDetail?.planTestCredit}</span>
                    </div>
                    <div className="col-3">
                        <span className="labletext px-0">{t('Total Credit', { ns: 'profile' })} :</span> <span className="labletext px-0">{accountDetail?.totalCredit}</span>
                    </div>
                    <div className="col-3"><span className="labletext px-0">{t('Balance Credit', { ns: 'profile' })} :</span>
                        <span className="labletext px-0"> {accountDetail?.balanceCredit}</span>
                    </div>

                </div>
                <div className="row py-3 align-items-center">
                    <div className='col-3'>
                        <span className="labletext px-0">{t('Additional Credits', { ns: 'profile' })} :</span>
                        <span className="labletext px-0"> {accountDetail?.additionalCreditTotalCount}</span>
                    </div>
                    <div className="col-3">
                        <span className="labletext px-0">{t('Additional Credits Left', { ns: 'profile' })} :
                        </span> <span className="labletext px-0">{accountDetail?.additionalCreditLeftCount}</span>
                    </div>
                    <div className="col-3">
                        <span className="labletext px-0">{t('Valid till Date', { ns: 'profile' })} :</span> <span className="labletext px-0">{accountDetail?.additionalCreditValidTillDate?.split('T')[0]}</span>
                    </div>
                    <div className="col-3">
                        <button className='buy-btn' onClick={openPayment}>Buy credits</button>
                    </div>

                </div>


                <div className="row">
                    <hr className="hr" />
                    <div className="col-12">

                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                    <span className="labletext px-0">{t('Payment Plans', { ns: 'profile' })}</span>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                    <span className="labletext px-0">{t('Apply Additional Coupon', { ns: 'profile' })}</span>
                                </button>
                            </li>

                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                <section className="pricing-table">
                                    <div className="container">
                                        <div className="block-heading">
                                            <h2 style={{ color: '#3b8f90' }}>{t('Our Pricing', { ns: 'profile' })}</h2>
                                        </div>

                                        <div className="row justify-content-md-center gappeo-pricing">

                                            <div className='pricing-tables'>

                                                <div className='feature-table'>
                                                    <div className='plan-table'>
                                                        <table className="table ">
                                                            <thead>
                                                                <th> <div className='feature'> {t('Feature', { ns: 'profile' })}</div></th>
                                                                <th >
                                                                    <div className='plan-box'>
                                                                        <p>{t('Free', { ns: 'profile' })}</p>
                                                                        <h1>$ 0</h1>
                                                                        {accountDetail?.planId === 1 ?
                                                                            <button
                                                                                style={{ backgroundColor: '#52A9F9' }}>
                                                                                {t('Current Plan', { ns: 'profile' })}
                                                                            </button> :
                                                                            <button
                                                                                style={{ visibility: 'hidden' }}>
                                                                                {t('Current Plan', { ns: 'profile' })}
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </th>
                                                                <th >
                                                                    <div className='plan-box' style={{ background: '#52A9F9' }}>
                                                                        <p>{t('STARTER', { ns: 'profile' })}</p>
                                                                        <h1>$ 799/yr</h1>
                                                                        {
                                                                            accountDetail?.planId === 8 ?
                                                                                <button
                                                                                    style={{ backgroundColor: '#52A9F9' }}>
                                                                                    {t('Current Plan', { ns: 'profile' })}
                                                                                </button> :
                                                                                <button
                                                                                    style={{ backgroundColor: '#52A9F9' }}>
                                                                                    <a style={{ color: '#fff' }}
                                                                                        rel='noreferrer'
                                                                                        href="https://www.paypal.com/ncp/payment/SSBZ4VZC4DLXQ"
                                                                                        target="_blank">
                                                                                        {t('Upgrade now', { ns: 'profile' })}
                                                                                    </a>
                                                                                </button>
                                                                        }
                                                                    </div>
                                                                </th>
                                                                <th >
                                                                    <div className='plan-box' style={{ background: '#5FBB97' }}>
                                                                        <p>{t('PREMIUM', { ns: 'profile' })}</p>
                                                                        <h1>$ 2199/yr</h1>
                                                                        {accountDetail?.planId === 9 ?
                                                                            <button
                                                                                style={{ backgroundColor: '#52A9F9' }}>
                                                                                {t('Current Plan', { ns: 'profile' })}
                                                                            </button> :
                                                                            <button
                                                                                style={{ backgroundColor: '#52A9F9' }}>
                                                                                <a style={{ color: '#fff' }}
                                                                                    rel='noreferrer'
                                                                                    href="https://www.paypal.com/ncp/payment/8JHG4DAPHB6R8"
                                                                                    target="_blank">
                                                                                    {t('Upgrade now', { ns: 'profile' })}
                                                                                </a>
                                                                            </button>
                                                                        }

                                                                    </div>
                                                                </th>
                                                                <th >
                                                                    <div className='plan-box' style={{ background: '#E8325D' }}>
                                                                        <p>{t('ENTERPRISE', { ns: 'profile' })}</p>
                                                                        <h1 style={{ fontSize: 15 }}>{t('Contact us', { ns: 'profile' })}</h1>
                                                                        {accountDetail?.planId === 10 ?
                                                                            <button
                                                                                style={{ backgroundColor: '#52A9F9' }}>
                                                                                {t('Current Plan', { ns: 'profile' })}
                                                                            </button> :
                                                                            <button
                                                                                style={{ backgroundColor: '#52A9F9' }}>
                                                                                <a style={{ color: '#fff' }}
                                                                                    rel='noreferrer'
                                                                                    href="https://gappeo-help.freshdesk.com/support/tickets/new"
                                                                                    target="_blank"
                                                                                >
                                                                                    {t('Contact us', { ns: 'profile' })}
                                                                                </a>
                                                                            </button>}
                                                                    </div>
                                                                </th>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{t('Candidate Invites', { ns: 'profile' })}</td>
                                                                    <td>50</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Test Credits', { ns: 'profile' })}</td>
                                                                    <td>10</td>
                                                                    <td>300</td>
                                                                    <td>1000</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Access to Test Library', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Role Based Assessment Packs', { ns: 'profile' })}</td>
                                                                    <td>5</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Create Test Packs', { ns: 'profile' })}</td>
                                                                    <td><img
                                                                        src='/images/close-check-grey.svg'
                                                                        alt='' />
                                                                    </td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                    <td>{t('Unlimited', { ns: 'profile' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Add your own tests', { ns: 'profile' })}</td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Coding Assessments', { ns: 'profile' })}</td>
                                                                    <td><img
                                                                        src='/images/close-check-grey.svg'
                                                                        alt='' /></td>
                                                                    <td><img
                                                                        src='/images/close-check-grey.svg'
                                                                        alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Generate custom test link', { ns: 'profile' })}</td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Customize Invite Email Templates', { ns: 'profile' })}</td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Multiple User Access', { ns: 'profile' })}</td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td>5</td>
                                                                    <td>10</td>
                                                                    <td>{t('limited', { ns: 'profile' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Assessment Reports', { ns: 'profile' })}</td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td>{t('Comprehensive', { ns: 'profile' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Custom Reports', { ns: 'profile' })}</td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('AI based Proctoring', { ns: 'profile' })}</td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td>{t('Advanced', { ns: 'profile' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Resume Ranking', { ns: 'profile' })} </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>{t('Custom Branding', { ns: 'profile' })} </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Whitelabelled URL', { ns: 'profile' })} </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('API Integration', { ns: 'profile' })}</td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            src='/images/close-check-grey.svg'
                                                                            alt='' />
                                                                    </td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                    <td> <img
                                                                        src='/images/green-check.svg'
                                                                        alt='' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Customer Support', { ns: 'profile' })} </td>
                                                                    <td>{t('Standard Support', { ns: 'profile' })}</td>
                                                                    <td>{t('Standard Support', { ns: 'profile' })}</td>
                                                                    <td>{t('Priority Support', { ns: 'profile' })}</td>
                                                                    <td>{t('Dedicated Support', { ns: 'profile' })}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                </section >
                            </div >
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="row py-3">
                                    <div className="col-7">
                                        <input type="text" className="form-control" placeholder={t('Coupon Code', { ns: 'profile' })} name="couponcode" value={coupon} id="couponcode"
                                            onChange={(e) => changeCoupon(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <button className="btn-txt1" type="button" onClick={apply} >{t('Apply', { ns: 'profile' })}</button>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </div >
    )
}
