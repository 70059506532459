
export const getTotal = (arr, type) => {
    if (type === 'ques') {
        return arr.reduce(
            (accumulator, currentValue) => accumulator + currentValue.questionCount,
            0,
        )
    }
    else {
        return arr.reduce(
            (accumulator, currentValue) => accumulator + currentValue[type],
            0,
        )
    }
}

export function validateEmails(arr) {
    // Regular expression for validating an Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Object to store unique email addresses
    const uniqueEmails = {};

    // Flag to check if any invalid email is found
    let isValid = true;

    arr.forEach((item) => {
        const email = item.value;

        // Check if the email is valid
        if (!emailRegex.test(email)) {
            isValid = false;
            console.log(`${email} is not a valid email address.`);
        }

        // Check for duplicate emails
        if (uniqueEmails[email]) {
            isValid = false;

        } else {
            uniqueEmails[email] = true;
        }
    });

    return isValid;
}

export function replaceObjectInArray(array, _id, newObject) {
    const idMap = new Map(array.map(obj => [obj.testID, obj]));
    if (idMap.has(_id)) {
        idMap.set(_id, { ...newObject, active: newObject.active, isSelected: true });
        return Array.from(idMap.values());
    }
    return array;
}


export const getDateAfterTwo = () => {
    var today = new Date();

    // Add two days to the current date
    var minDate = new Date(today);
    minDate.setDate(today.getDate() + 2);

    // Format the date as "YYYY-MM-DD" for the input field
    var formattedMinDate = minDate.toISOString().split('T')[0];
    return formattedMinDate
}

export function isDateAfter30Days(selectedDate) {
    var today = new Date();

    // Add two days to the current date
    var minDate = new Date(today);
    minDate.setDate(today.getDate() + 30);

    // Format the date as "YYYY-MM-DD" for the input field
    var formattedMinDate = minDate.toISOString().split('T')[0];
    return formattedMinDate
}

export function convertToUTC(dateString) {
    // Parse the input date string
    let dateObject = new Date(dateString);

    // Get the time zone offset in minutes
    let timeZoneOffset = dateObject.getTimezoneOffset();

    // Adjust the date object to the local time zone
    dateObject.setMinutes(dateObject.getMinutes() - timeZoneOffset);

    // Create a new date object with the adjusted time as UTC
    let utcDateObject = new Date(dateObject);

    // Format the UTC date object as a string
    let utcDateString = utcDateObject.toISOString();

    return utcDateString;
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl?.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export const StarIcon = ({ filled, onClick }) => {
    return (
        <span
            style={{ cursor: 'pointer' }}
            onClick={onClick}
        >
            {filled ? <img src='/images/star-filled.svg' alt='' /> : <img src='/images/star.svg' alt='' />}
        </span>
    );
};

export const removeScript = () => {
    // Remove the Google Translate element
    var translateElement = document.getElementById('google_translate_element');
    if (translateElement) {
        translateElement.parentNode.removeChild(translateElement);
    }

    // Remove the Google Translate script
    var translateScript = document.getElementById('google_translate_script');
    if (translateScript) {
        translateScript.parentNode.removeChild(translateScript);
    }

    var translateScriptSrc = document.getElementById('google_translate_script_src');
    if (translateScriptSrc) {
        translateScriptSrc.parentNode.removeChild(translateScriptSrc);
    }

    // Optionally, remove any iframes created by Google Translate
    var iframes = document.getElementsByTagName('iframe');
    for (var i = iframes.length - 1; i >= 0; i--) {
        if (iframes[i].src.indexOf('translate.google.com') > -1) {
            iframes[i].parentNode.removeChild(iframes[i]);
        }
    }
}

export const convertDate = (d1) => {
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const date = new Date(d1)
    function pad(s) { return (s < 10) ? '0' + s : s; }
    let d = new Date(date)
    return [pad(d.getDate()), pad(monthNames[d.getMonth()]), pad(d.getFullYear())].join('-')
}

export const formatAMPM = (d) => {
    let date = new Date(d)
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}


const Codes = [
    {
        type: 'ISTJ',
        description: 'Dependable and methodical, they value tradition and organization. Known for their reliability and diligence, ISTJs thrive in structured environments.',
        nickname: 'The Inspector'
    },
    {
        type: 'ISFJ',
        description: 'Caring and nurturing, ISFJs are committed to their duties and take pride in helping others, often placing the needs of others before their own.',
        nickname: 'The Protector'
    },
    {
        type: 'INFJ',
        description: 'Idealistic and compassionate, INFJs seek deeper connections and meanings in life, often focused on helping others grow and improve.',
        nickname: 'The Advocate'
    }, {
        type: 'INTJ',
        description: 'Strategic and logical, INTJs enjoy solving complex problems and are driven by the pursuit of knowledge, often seen as visionary in their approach.',
        nickname: 'The Architect'
    },
    {
        type: 'ISTP',
        description: 'Practical and reserved, ISTPs excel in troubleshooting and hands-on tasks, often enjoying physical engagement with the world and mastering technical skills.',
        nickname: 'The Craftsman'
    },
    {
        type: 'ISFP',
        description: 'Creative and sensitive, ISFPs appreciate beauty and express themselves through various artistic pursuits, valuing harmony and personal expression.',
        nickname: 'The Artist'
    },
    {
        type: 'INFP',
        description: 'Reflective and empathetic, INFPs are guided by deep personal values, often seeking to express themselves through creative writing or art.',
        nickname: 'The Mediator'
    },
    {
        type: 'INTP',
        description: 'Analytical and abstract, INTPs are theorists who enjoy deep thinking and conceptualizing ideas, often appearing detached as they ponder complex issues.',
        nickname: 'The Thinker'
    },
    {
        type: 'ESTP',
        description: 'Energetic and pragmatic, ESTPs are action-oriented and thrive in dynamic, fast-paced environments, often drawn to adventurous or risky activities.',
        nickname: 'The Doer'
    },
    {
        type: 'ESFP',
        description: 'Sociable and spontaneous, ESFPs enjoy engaging with others and the world around them, often found in the center of attention and bringing joy to social settings.',
        nickname: 'The Performer'
    },
    {
        type: 'ENFP',
        description: 'Enthusiastic and imaginative, ENFPs are motivators who see possibilities everywhere, often starting new projects and inspiring others with their vision.',
        nickname: 'The Champion'
    },
    {
        type: 'ENTP',
        description: 'Innovative and curious, ENTPs thrive on new ideas and love to challenge others in debate, often analyzing situations and people in depth.',
        nickname: 'The Debator'
    },
    {
        type: 'ESTJ',
        description: 'Organized and direct, ESTJs value structure and order, often taking leadership roles and implementing efficient systems and rules.',
        nickname: 'The Supervisor'
    },
    {
        type: 'ESFJ',
        description: `Warm and cooperative, ESFJs seek harmony and care deeply about others' well-being, often working diligently to organize and maintain structured, friendly environments.`,
        nickname: 'The CareGiver'
    },
    {
        type: 'ENFJ',
        description: `Charismatic and compassionate, ENFJs are naturally tuned into others' feelings and strive to help and guide, often taking on mentorship or leadership roles.`,
        nickname: 'The Giver'
    },
    {
        type: 'ENTJ',
        description: 'Assertive and strategic, ENTJs are natural leaders who like to organize and plan, driving towards efficiency and achieving goals with boldness and confidence.',
        nickname: 'The Commander'
    },
]



export const getMbtiResult = (code) => {
    const text = Codes?.filter(el => el.type === code)[0]
    return [text]
}