import React, { useEffect, useState } from 'react';
import { getCodingQuestionLanguages, updateLanguageText } from '../../Redux/ActionCreators/testLibraryAction';
import { useDispatch, useSelector } from 'react-redux';
import Editor from "@monaco-editor/react";
import { returnErrors } from '../../Redux/ActionCreators/errorAction';
import { useTranslation } from 'react-i18next';

export default function CreateCodeText() {
    const [language, setLanguage] = useState('');
    const [text, setText] = useState('');
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { codingQuestionLanguagesOptions } = useSelector(state => state.testLibrary);

    useEffect(() => {
        dispatch(getCodingQuestionLanguages());
    }, [])

    const selectLanguage = (val) => {
        setLanguage(val);
        const filter = codingQuestionLanguagesOptions?.filter(el => el.id === Number(val))[0];
        setText(filter.defaultCode);
        onChangeCode('code', filter.defaultCode);
    }


    const handleEditorChange = (value) => {
        setText(value);
        onChangeCode("code", value);
    };

    const onChangeCode = (action, data) => {
        console.log(action, data)
        switch (action) {
            case "code": {
                setText(data)
                break;
            }
            default: {
                console.warn("case not handled!", action, data);
            }
        }
    };


    const submitData = async () => {
        if (language === '') {
            returnErrors(dispatch, (t(`Language can't empty`)), 500)
        }
        else if (text === '') {
            returnErrors(dispatch, (t(`Text can't empty`)), 500)
        } else {
            let obj = {
                "id": language,
                "defaultCode": text
            }
            const res = await dispatch(updateLanguageText(obj));
            if (res?.type === 'UPDATE_LANGUAGE') {
                dispatch(getCodingQuestionLanguages());
                setLanguage('');
                setText('');
            }
        }
    }



    return (
        <div className='add-text'>
            <div className='container'>
                <div className='row'>
                    <h2 className='text-center'>{t('Add Default Text')}</h2>
                    <div className='col-md-8'>
                        <div className='form-group mt-3'>
                            <label>{t('Language')}</label>
                            <select className='form-control mt-2' value={language} onChange={(e) => selectLanguage(e.target.value)}>
                                <option hidden>{t('Select Language')}</option>
                                {
                                    codingQuestionLanguagesOptions && codingQuestionLanguagesOptions?.map(el => {
                                        return (
                                            <option value={el.id}>{el.displayLanguage}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group mt-3'>
                            <label>{t('Text')}</label>
                            <div style={{ border: '1px solid #0000001a', marginTop: 10 }}>
                                <Editor
                                    height="60vh"
                                    width={`100%`}
                                    language={language || "javascript"}
                                    value={text}
                                    theme={'vs-light'}
                                    defaultValue="// some comment"
                                    onChange={handleEditorChange}

                                    options={{
                                        autoIndent: 'full',
                                        contextmenu: true,
                                        fontFamily: 'monospace',
                                        lineHeight: 24,
                                        hideCursorInOverviewRuler: true,
                                        inlineSuggest: true,
                                        fontSize: "16px",
                                        formatOnType: true,
                                        autoClosingBrackets: true,
                                        minimap: { enabled: false },
                                        scrollbar: {
                                            horizontalSliderSize: 4,
                                            verticalSliderSize: 18,
                                        },
                                        selectOnLineNumbers: true,
                                        roundedSelection: false,
                                        readOnly: false,
                                        cursorStyle: 'line',
                                        automaticLayout: true,
                                    }}
                                />
                            </div>

                        </div>
                        <div className='form-group mt-3'>
                            <button onClick={submitData}>{t('Submit')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

