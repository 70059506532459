import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';


export default function ConfirmModal({ isConfirm, setIsConfirm, removeConfirm, type }) {
    const { t } = useTranslation();
    return (
        <div>
            {/* ########## Confirm Delete modal ############### */}
            <Modal
                show={isConfirm}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"confirm-modal "}
            >
                <div className="p-4" >
                    <h1>
                        {t('Are you sure you want to remove this Question')} ?
                    </h1>
                    <div className="save-btn">
                        <button onClick={removeConfirm}>{t('Yes')}</button>&nbsp;&nbsp;
                        <button onClick={() => setIsConfirm(false)}>{t('No')}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
