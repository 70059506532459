import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getInvitationTemplates, previewTemplate, closeInviteModal, updateTemplate } from '../../../Redux/ActionCreators/testLibraryAction';
import { BodyEditor, FooterEditor, TemplateEditor } from '../../../utils/editor';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';

export default function Template({ testId }) {
    const [isPreview, setIsPreview] = useState(false);
    const { templateType, templates, templateText, editTemplate, emailBody, footer, instruction, templateId } = useSelector(state => state.testLibrary);
    const [edit, setEdit] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const openPreview = async () => {
        await setIsPreview(true)
        await dispatch(previewTemplate())
    }
    const saveTemplate = async () => {

        let obj = {
            "templateId": templateId,
            "template": templates,
            EmailBody: emailBody,
            footer: footer,
            additionalInstruction: instruction
        }
        const res = await dispatch(updateTemplate(obj));
        if (res?.type === 'UPDATE_TEMPLATE') {
            dispatch(getInvitationTemplates(testId, templateType, false));
            setEdit(false);
            !edit && await dispatch(closeInviteModal());
        }
    }

    return (
        <div className='template-details'>
            <div className='template-header'>
                <img className='cursor-pointer' src='/images/back.svg' onClick={() => dispatch(closeInviteModal())} alt='' />
                <h4>{editTemplate ? 'Edit Template' : (edit) ? 'Edit Template' : 'View Template'}</h4>
                {editTemplate ?
                    <button onClick={saveTemplate}>{t('Save', { ns: 'home' })}</button> :
                    (edit) ?
                        <button onClick={saveTemplate}>{t('Save', { ns: 'home' })}</button> :
                        <button onClick={() => {
                            setEdit(true)
                            // dispatch(getInvitationTemplates(testId, templateType, true))
                        }}>{t('Edit', { ns: 'home' })}</button>
                }
            </div>
            <div className='template-desc d-flex flex-column align-items-center'>
                <div className='template-image text-center w-100'>
                    <img src='/images/editor-img.svg' alt='' width={(!editTemplate && !edit) ? 594 : '60%'} />
                    <div className='template-text'>
                        <h3>GAPPEO</h3>
                        <p>{t('Skill Test Platform', { ns: 'home' })} </p>
                    </div>
                </div>
                {editTemplate ?
                    <div style={{ width: '60%' }}>
                        <TemplateEditor content={instruction} type='template' />
                    </div> :
                    edit ?
                        <div style={{ width: '60%' }}>
                            <div className='d-flex justify-content-end mt-2'>
                                <button className='preview-btn' onClick={() => openPreview()}>Preview</button>
                            </div>
                            <div className='form-group mt-2'>
                                <label>Email Body</label>
                                <BodyEditor content={emailBody} type='email' />
                            </div>
                            <div className='form-group mt-2'>
                                <label>Signature</label>
                                <FooterEditor content={footer} type='footer' />
                            </div>

                        </div>
                        : <div dangerouslySetInnerHTML={{ __html: templates }} />
                }
            </div>
            <Modal
                show={isPreview}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"preview-modal"}
            >
                <div className='sticky-close'>
                    <img src='/images/close.svg' alt='' onClick={() => setIsPreview(false)} />
                </div>
                <div className="p-4" >
                    <div dangerouslySetInnerHTML={{ __html: templateText }} />
                </div>
            </Modal>
        </div >
    )
}
