// test/list-my-test
import { returnErrors } from './errorAction';
import { GET_MY_ASSESSMENTS, GET_NEXT_MY_ASSESSMENTS, RESET_TEST_LOADER, NO_TESTS } from '../Types/types';
import { getApiCall } from '../../utils/request';

export const getMyTests = (query, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/list-my-test?SearchText=${query}&PageNumber=${page}&DisplayRows=${row}`);
        if (response?.status === 200) {
            dispatch({ type: GET_MY_ASSESSMENTS, payload: response.data });
        } else if (response?.status === 204) {
            dispatch({ type: NO_TESTS });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getNextTest = (query, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/list-my-test?SearchText=${query}&PageNumber=${page}&DisplayRows=${row}`);
        if (response?.status === 200) {
            dispatch({ type: GET_NEXT_MY_ASSESSMENTS, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const resetTestLoader = () => (dispatch) => {
    return dispatch({ type: RESET_TEST_LOADER })
}

