import React, { Fragment, useEffect, useState } from 'react';
import './testLibrary.scss';
import CreateCodingQuestion from '../CreateCodingQuestion/createCodingQuestion';
import CodingQuestionList from '../CreateCodingQuestion/codingQuestionList';
import { useDispatch } from 'react-redux';
import { getCodingQuestionLanguages, resetCodingQuestions, getDefaultCodingQuestions, getQuestionLevels, removeFromSelect, saveSelectCodingQuestion, createCodingTest, updateCodingTest, editedCodingQuestion } from '../../Redux/ActionCreators/testLibraryAction';
import { useSelector } from 'react-redux';
import { ThreeDots } from '../../Loader/loader';
import Select from 'react-select';
import { getTotal } from '../../utils/miscelleneous';
import { returnErrors } from '../../Redux/ActionCreators/errorAction';
import { Modal } from 'react-bootstrap';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function SelectCodingTest(props) {
    const [testName, setTestName] = useState('');
    const [isAdd, setIsAdd] = useState(false);
    const [isList, setIsList] = useState(false);
    const [testLevel, setTestLevel] = useState('');
    const [programmingLanguages, setProgrammingLanguages] = useState([]);
    const [loadQues, setLoadQues] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [details, setDetails] = useState('');
    const { t } = useTranslation();
    const [time, setTime] = useState(10);
    const [score, setScore] = useState(20);
    const [loading, setLoading] = useState(false);
    console.log(props.edit)
    const dispatch = useDispatch();
    const { codingQuestionLanguagesOptions, questionLevel, loadingCodingQuestion, selectedCodingQuestion, codingTestById } = useSelector(state => state.testLibrary);

    useEffect(() => {
        if (!isAdd || isAdd) {
            dispatch(getCodingQuestionLanguages());
            dispatch(getQuestionLevels())
            setProgrammingLanguages({ label: props.langId?.displayLanguage, value: props.langId?.id });
        }
    }, [])

    useEffect(() => {
        if (props.edit && codingTestById) {
            setIsAdd(false);
            setLoadQues(true);
            setTestName(codingTestById?.testName);
            setTestLevel(codingTestById?.testLevelId);
            setProgrammingLanguages({ label: codingTestById?.languages[0]?.displayLanguage, value: codingTestById?.languages[0]?.codingLanguageId });

        }
    }, [props.edit])


    const selectLanguage = (e) => {
        setProgrammingLanguages(e)
    };

    const selectLevel = (val) => {
        setTestLevel(val);
    }

    const loadQuestions = async () => {
        console.log(programmingLanguages?.value)
        await setLoadQues(true)
        await dispatch(resetCodingQuestions());
        await dispatch(getDefaultCodingQuestions(testLevel, programmingLanguages?.value));
    }

    const saveTest = async () => {
        await setLoading(true);
        if (programmingLanguages?.value === '') {
            // setQuestionTitleError('Question name required!');
            returnErrors(dispatch, (t('Language is required!')), 500);
            return false;
        } else if (testLevel === '') {
            // setQuestionTitleError('Question name required!');
            returnErrors(dispatch, (t('Test Level is required!')), 500);
            return false;
        }
        else if (!selectedCodingQuestion?.length) {
            returnErrors(dispatch, (t('Select at least one question')), 500);
            return false;
        }
        else {

            let obj = {
                "name": `${programmingLanguages?.label}-${testLevel === '1' ? 'Beginner' : testLevel === '2' ? 'Advanced' : 'Intermediate'} Coding test`,
                "duration": getTotal(selectedCodingQuestion, 'duration'),
                "questionCount": selectedCodingQuestion?.length,
                "testLevel": parseInt(testLevel),
                "languageIds": [
                    programmingLanguages?.value
                ],
                "questions": !props.edit ? selectedCodingQuestion?.map(el => [{ questionId: el.id, duration: el.duration, score: (el.score) }][0]) :
                    selectedCodingQuestion?.map(el => [{ id: el.id, questionId: el.questionId, duration: el.duration, score: (el.score) }][0])
            }
            if (props.edit) {
                const res = await dispatch(updateCodingTest({ ...obj, id: props.testid }));
                if (res?.type === 'UPDATE_CODING_TEST') {
                    await props.goToTest();
                }

            } else {
                const res = await dispatch(createCodingTest(obj));
                if (res?.type === 'CREATE_CODING_TEST') {
                    await props.goToTest();
                }
            }
        }
        await setLoading(false)
    }

    const openAdd = async () => {
        await setIsAdd(true);
    }

    const openList = () => {
        setIsList(true);

    }

    const backToAdd = () => {
        setIsList(false);
    }



    const goBack = () => {
        setIsAdd(false);
    }

    const openEdit = (data) => {
        console.log(data)
        setIsEdit(true);
        setDetails(data);
        setTime(data.duration);
        setScore(data.score)
    }

    const editCodingQuestion = () => {
        if (time === '') {
            returnErrors(dispatch, `Time can't empty`, 500)
        }
        else if (score === '') {
            returnErrors(dispatch, `Score can't empty`, 500)
        }

        else {
            let obj = {
                id: details.id,
                duration: time,
                score: score,
            }
            dispatch(editedCodingQuestion(obj));
            setIsEdit(false);
        }
    }
    return (
        <div className='coding-test-container'>
            <Dialog
                className='screen-loader'
                open={loading}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {t('Loading', { ns: 'home' })}...
            </Dialog>
            {
                isList ?
                    <CodingQuestionList
                        backToAdd={backToAdd}
                        testLevel={testLevel}
                        language={programmingLanguages?.value}
                        questions={selectedCodingQuestion}
                        saveSelectQuestion={(ques) => {
                            dispatch(saveSelectCodingQuestion(ques))
                            setIsList(false)
                            setIsAdd(false)
                        }}
                    />
                    : !isAdd ?
                        <Fragment>
                            <div className='coding-header'>
                                <img src='/images/back.svg' alt='' className='cursor-pointer' onClick={props.goToTest} />
                                <div>
                                    <h3>{props.edit ? (t('Edit Coding Test', { ns: 'home' })) : (t('Coding Test', { ns: 'home' }))}</h3>
                                    {!props.edit ? <p>{t('Generate coding test', { ns: 'home' })} {programmingLanguages?.label}</p> : null}
                                </div>
                            </div>
                            <div className='row'>
                                {/* <div className='col-md-6'>
                                    <div className='test-input'>
                                        <label>Test Name</label>
                                        <input type='text' placeholder={t('Enter Test Name' , {ns : 'home'})} value={testName} disabled={props.edit} className='form-control mt-2' onChange={(e) => setTestName(e.target.value)} />
                                    </div>
                                </div> */}
                                <div className='col-md-6'>

                                    <div className='test-input mt-4'>
                                        <label>{t('Programing Language', { ns: 'home' })}</label>
                                        <div className='mt-2'>
                                            <Select
                                                placeholder={t('Select Languages', { ns: 'home' })}
                                                options={codingQuestionLanguagesOptions?.map(el => [{ label: el.displayLanguage, value: el.id }][0])}
                                                value={programmingLanguages}
                                                onChange={(e) => selectLanguage(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='test-input mt-4'>
                                        <label>{t('Test Level', { ns: 'home' })}</label>
                                        {/* questionLevel */}
                                        <div >
                                            <select className='form-control mt-2' value={testLevel} onChange={(e) => selectLevel(e.target.value)}>
                                                <option hidden>{t('Select Level', { ns: 'home' })}</option>
                                                {/* <option value={0} >All</option> */}
                                                <option value={1} >{t('Beginner', { ns: 'home' })}</option>
                                                <option value={3} >{t('Intermediate', { ns: 'home' })}</option>
                                                <option value={2} >{t('Advanced', { ns: 'home' })}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col-md-3 mt-5'>
                                    <button className='load-btn mt-2' disabled={(testLevel === '') || (programmingLanguages === '')} onClick={loadQuestions}>{t('Load Questions', { ns: 'home' })}</button>
                                </div>
                            </div>


                            {(loadQues && selectedCodingQuestion?.length) ?
                                <div className='coding-question-list'>
                                    <div className='list-header'>
                                        <div>
                                            <h5>{t('Question List', { ns: 'home' })}</h5>
                                            <p>{selectedCodingQuestion?.length} {t('Questions', { ns: 'home' })} {getTotal(selectedCodingQuestion, 'duration')} {t('mins', { ns: 'home' })}</p>
                                        </div>
                                        <div>
                                            <button className='outline-btn' onClick={() => openList()}>{t('ADD QUESTION FROM LIST', { ns: 'home' })}</button>&nbsp;&nbsp;
                                            <button className='outline-btn' onClick={() => openAdd()}>{t('ADD NEW QUESTION', { ns: 'home' })}</button>
                                        </div>
                                    </div>
                                    <div className='count-header'>
                                        <div className='d-flex align-items-center'>
                                            <h4>{t('Question', { ns: 'home' })}</h4>&nbsp; <span>{selectedCodingQuestion.length}</span>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <h4>{t('Time(mins)', { ns: 'home' })}</h4>&nbsp; <span>{getTotal(selectedCodingQuestion, 'duration')}</span>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <h4>{t('Score', { ns: 'home' })}</h4> &nbsp;<span>{getTotal(selectedCodingQuestion, 'score')}</span>
                                        </div>
                                    </div>
                                    <div className='lists'>
                                        {loadingCodingQuestion ?
                                            <div>
                                                <ThreeDots />
                                            </div> :

                                            selectedCodingQuestion && selectedCodingQuestion?.length > 0 ?
                                                selectedCodingQuestion.map((el, i) => {
                                                    return (
                                                        <div className='question-list'>
                                                            <div >
                                                                Q {i + 1}
                                                                {/* <input type='checkbox' style={{ width: 16, height: 16 }} value={el.questionID} checked={el.isChecked} onChange={(e) => selectQuestion(e, el)} /> */}
                                                            </div>&nbsp;&nbsp;&nbsp;
                                                            {/* <div className='elipsis-2' dangerouslySetInnerHTML={{ __html: el.question }} /> */}

                                                            <div>{el.name}</div>
                                                            <div>{el.difficultyLevelName}</div>
                                                            {window.innerWidth > 767 && <div>{el.duration} Mins</div>}
                                                            {window.innerWidth > 767 && <div>Score:{el.score}</div>}
                                                            <div style={{ color: '#009199' }}>
                                                                <img src='/images/edit-icon.svg' className='cursor-pointer' onClick={() => openEdit(el)} alt='' />&nbsp;
                                                                {window.innerWidth > 767 && 'EDIT'}
                                                            </div>
                                                            <div>
                                                                <img src='/images/delete-icon.svg' alt='' className='cursor-pointer' onClick={() => dispatch(removeFromSelect(el))} />
                                                            </div>

                                                        </div>
                                                    )
                                                }) :
                                                <div className='empty-list'>
                                                    <img src='/images/empty-list.svg' alt='' />
                                                </div>
                                        }

                                    </div>
                                    <button className='save-btn mt-3' onClick={() => saveTest()}>{t('SAVE AND CONTINUE', { ns: 'home' })}</button>&nbsp;&nbsp;

                                </div> :
                                null
                            }
                        </Fragment> :
                        isAdd ?
                            <CreateCodingQuestion
                                goBack={goBack}
                                addFromList={true}
                            /> : null
            }

            {/* ##########Edit coding test############### */}
            <Modal
                show={isEdit}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"edit-modal"}
            >
                <div className='sticky-holder' onClick={() => setIsEdit(false)}>
                    <img src='/images/close.svg' alt='' />
                </div>
                <div className="p-4" >
                    <h2>
                        {t('Edit Test', { ns: 'home' })}

                    </h2>
                    <p>{t('Select the below options to continue', { ns: 'home' })}</p>
                    <div className='mt-2'>

                        <div className='form-group mt-3'>
                            <label>{t('Test time(mins)', { ns: 'home' })}</label>
                            <div className='items'>
                                <div className={time === details.duration ? 'item active' : 'item'} onClick={() => setTime(time)}>
                                    {details.duration}
                                </div>

                                <div >
                                    <input type='number' className='form-control' placeholder={t('Custom', { ns: 'home' })} onChange={(e) => setTime(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group mt-3'>
                            <label>{t('Test Score', { ns: 'home' })}</label>
                            <div className='items'>
                                <div className={score === details?.score ? 'item active' : 'item'} onClick={() => setScore(score)}>
                                    {details?.score}
                                </div>

                                <div >
                                    <input type='number' className='form-control' placeholder={t('Custom', { ns: 'home' })} onChange={(e) => setScore(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="save-btn">
                        <button onClick={editCodingQuestion}>{t('CONTINUE', { ns: 'home' })}</button>
                    </div>

                    <div className='notified-text'>
                        <img src='/images/bell.svg' alt='' />&nbsp;
                        <p>{t("To invite candidates to an existing coding test you already created,  start from 'My Tests'", { ns: 'home' })}</p>
                    </div>
                </div>
            </Modal>

        </div>
    )
}
