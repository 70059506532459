import { returnErrors } from './errorAction';
import { GET_USERS, GET_SUBSCRIPTION_PLANS, UPDATE_SUBSCRIPTION_PLANS, NO_USER_FOUND, GET_NEXT_USERS, RESET_USERS_LIST, ADD_USERS, UPDATE_STATUS, GET_TENANTS, GET_NEXT_TENANTS, GET_TENANTS_USER, UPDATE_TENANT_STATUS } from '../Types/types';
import { getApiCall, postApiCall } from '../../utils/request';

export const getUsers = (query, page, rows) => async (dispatch) => {
    try {
        const response = await getApiCall(`user/list-user?SearchText=${encodeURIComponent(query)}&PageNumber=${page}&DisplayRows=${rows}`);
        if (response.status === 200) {
            dispatch({ type: GET_USERS, payload: response.data })
        } else {
            dispatch({ type: NO_USER_FOUND, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getNextUsers = (query, page, rows) => async (dispatch) => {
    try {
        const response = await getApiCall(`user/list-user?SearchText=${encodeURIComponent(query)}&PageNumber=${page}&DisplayRows=${rows}`);
        if (response.status === 200) {
            dispatch({ type: GET_NEXT_USERS, payload: response.data })
        } else {
            dispatch({ type: NO_USER_FOUND, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const resetUserList = () => async (dispatch) => {
    return dispatch({ type: RESET_USERS_LIST })
}

export const addUser = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/add-user`, obj);
        if (response.status === 200) {
            return dispatch({ type: ADD_USERS, payload: response.data })
        } else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error?.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const changeStatus = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/update-user-status`, obj);
        if (response.status === 200) {
            return dispatch({ type: UPDATE_STATUS, payload: obj })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getTenants = (query, page, rows) => async (dispatch) => {
    try {
        const response = await getApiCall(`user/list-tenant?SearchText=${encodeURIComponent(query)}&PageNumber=${page}&DisplayRows=${rows}`);
        console.log(response.data)
        if (response.status === 200) {
            dispatch({ type: GET_TENANTS, payload: response.data })
        } else {
            // dispatch({ type: NO_USER_FOUND, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}



export const getNextTenants = (query, page, rows) => async (dispatch) => {
    try {
        const response = await getApiCall(`user/list-tenant?SearchText=${encodeURIComponent(query)}&PageNumber=${page}&DisplayRows=${rows}`);
        console.log(response.data)
        if (response.status === 200) {
            dispatch({ type: GET_NEXT_TENANTS, payload: response.data })
        } else {
            // dispatch({ type: NO_USER_FOUND, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

// Get Subscripionplan list
export const getUserSubscriptionPlan = () => async (dispatch) => {
    try {
        const response = await getApiCall(`setting/get-subscription-plans`);
        if (response.status === 200) {
            dispatch({ type: GET_SUBSCRIPTION_PLANS, payload: response.data })
        } else {
            // dispatch({ })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

// Update Plan
export const updateSubscriptionPlan = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`setting/update-subscription-plan`, obj);
        if (response.status === 200) {
            return dispatch({ type: UPDATE_SUBSCRIPTION_PLANS, payload: response.data })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

// Get Tenant Profile

export const getTenantsProfile = (TenantId, UserId) => async (dispatch) => {
    try {
        const response = await getApiCall(`user/tenant-user?TenantId=${TenantId}&UserId=${UserId}`);
        if (response.status === 200) {
            return dispatch({ type: GET_TENANTS_USER, payload: response.data })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

// Update Tenant Status
export const updateTenantStatus = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/update-user-status`, obj);
        if (response.status === 200) {
            return dispatch({ type: UPDATE_TENANT_STATUS, payload: response.data })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}


