export default class HttpClient {
    static get() {
        return Promise.resolve(`Name,EmailId
        nandan,nb1@gappeo.com
        nandan,nb2@gappeo.com
        nandan,nb3@gappeo.com
        nandan,nb4@gappeo.com
        nandan,nb@gappeo.com
        nandan,nb5@gappeo.com
        rajat,rj@gappeo.com`);
    }
}
