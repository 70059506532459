import { GET_PLANS, GET_ACCOUNT_DETAIL } from "../Types/types";

let intialState = {
    plans: [],
    isTestLoading: true,
    editTemplate: false,
    isTemplate: false
}


export default function settingReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_PLANS:
            stateCopy.plans = action.payload;
            return stateCopy;
        case GET_ACCOUNT_DETAIL:
            stateCopy.accountDetail = action.payload
            return stateCopy;
        default:
            return stateCopy;
    }
}