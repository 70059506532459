import React, { useEffect, useState, Fragment } from 'react';
import './testResult.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Stepper from './stepper';
import { getSelectedTests, checkValidTest, updateTestName, getTenantUsers, getInvitationTemplates, sendInvites } from '../../../Redux/ActionCreators/testLibraryAction';
import { useSelector, useDispatch } from 'react-redux';
import AddTestModal from '../../../utils/addTestModal';
import Template from './template';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { getDateAfterTwo, isDateAfter30Days, validateEmails } from '../../../utils/miscelleneous';
import moment from 'moment';
import TestDetailModal from '../../../utils/testDetailModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Modal, Table } from 'react-bootstrap';
import { getAccountDetail } from '../../../Redux/ActionCreators/settingAction';
import HttpClient from "./httpClient";
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

//1- invite
// 2- reminder
export default function InvitePreview() {
    const [isOpen, setIsOpen] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [testName, setTestName] = useState('');
    const [testNameErr, setTestNameErr] = useState('');
    const [description, setDescription] = useState('');
    const [emails, setEmails] = useState('');
    const [emailsError, setEmailsError] = useState('');
    const [tenentEmail, setTenentEmail] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [date, setDate] = useState('');
    const [dateError, setDateError] = useState('');
    const [loading, setLoading] = useState(false);
    const [generatetestUrl, setGeneratetestUrl] = useState('');
    const [successMessageText, setsuccessMessageText] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [successEnable, setSuccessEnable] = useState(false);
    const [isList, setIsList] = useState(false);
    const [file, setFile] = useState();
    const { t } = useTranslation();
    const [array, setArray] = useState([]);
    const [errors, setErrors] = useState([]);
    const [dataInCSV, setDataInCSV] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [shouldValidate, setShouldValidate] = useState(false);
    const [modalMessage, setModalMessage] = useState(t('Please upgrade your plan to generate link'));
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { testDetails, isTemplate, tenentUsers } = useSelector(state => state.testLibrary);
    const { accountDetail } = useSelector(state => state.setting);
    const userDetails = JSON.parse(localStorage.getItem("profileDetails"));
    const canInvite = userDetails?.accessMatrix?.canGenerateLink ?? true;

    const { testId } = location.state;

    useEffect(() => {
        document.body.style.overflow = "auto"
        const { testId } = location.state
        const firstRender = async () => {
            const res = await dispatch(getSelectedTests(testId));
            if (res?.type === 'GET_TEST_DETAILS') {
                setDate(res.payload.validTillDate ? res.payload.validTillDate?.split('T')[0] : getDateAfterTwo());
            }
            dispatch(getTenantUsers());
            dispatch(getAccountDetail());
        }
        firstRender();
    }, [])
    console.log(date)

    useEffect(() => {
        HttpClient.get().then(res => setDataInCSV(res));
    }, []);



    const goBack = () => {
        navigate('/home/add-question', {
            state: {
                testId: location.state.testId,
                next: false
            }
        })
    }


    const openAddModal = () => {
        setIsOpen(true);
        setTestName(testDetails.testName);
        setDescription(testDetails?.testDescription);
        setTestNameErr('');
        setShouldValidate(false);
    }


    useEffect(() => {
        if (!shouldValidate) return

        const delay = setTimeout(() => {
            validateNameViaApi(testName);
        }, 500);

        return () => clearTimeout(delay);
    }, [testName, shouldValidate]);



    const validateNameViaApi = async (input) => {
        const res = input !== '' && await dispatch(checkValidTest(input));
        if (res?.payload?.isValid) {
            setTestNameErr('');
        } else {
            setTestNameErr(t('Test Name already exist'));
        }
    };

    const goToQuestion = async () => {
        if (testName.trim('') === '') {
            setTestNameErr(t('Please Enter Test Name'));
        }
        else {
            let obj = {
                "testName": testName,
                testDescription: description,
                testId: location.state.testId
            }
            const res = await dispatch(updateTestName(obj));

            if (res?.type === "UPDATE_TEST_NAME") {
                await setIsOpen(false);
                setTestNameErr('');
            }
        }
    }


    const changeTenentEmail = async (e) => {
        setTenentEmail(e);
    }

    const changeEmail = async (e) => {
        setSuccessMsg('');
        setEmailsError('');
        const isValid = await validateEmails(e);
        if (isValid) {
            setEmails(e);
            setSuccessMsg(t("All emails are valid"));
        } else {
            e.pop();
            setEmails(e);
            setSuccessMsg('');
        }

    }

    const sendInvite = async () => {
        if (date === '') {
            setDateError(t('Date is required'))
        }
        else if (!emails?.length && !tenentEmail?.length && !array?.length) {
            setEmailsError(t('Enter Email address'))
        }

        else {
            setSuccessMsg('');
            setEmailsError('');
            setDateError('');
            setLoading(true);

            let obj = {
                "testInvites": emails?.length ? emails.map(el => [{ name: null, emailId: el.value }][0]).concat(tenentEmail ? tenentEmail.map(el => [{ name: el.label, emailId: el.value }][0]) : []).concat(array ? array.map(el => [{ name: el.Name, emailId: el.EmailId }][0]) : []) : [].concat(tenentEmail ? tenentEmail.map(el => [{ name: el.label, emailId: el.value }][0]) : []).concat(array ? array.map(el => [{ name: el.Name, emailId: el.EmailId }][0]) : []),
                "testId": location.state.testId,
                "testURL": window.location.protocol + '//' + window.location.host + '/candidate-test/token',
                "validTill": moment.utc(new Date(date)).format()
            }
            const prevInvite = accountDetail?.candidateInviteCount;
            const currentInvite = obj.testInvites?.length;
            const totalInvite = prevInvite + currentInvite;
            const inviteAllowed = accountDetail?.accessMatrix?.candidateInvites;
            if (inviteAllowed == -1) {
                const res = await dispatch(sendInvites(obj));
                if (res?.type === 'SEND_INVITES') {
                    navigate('/assessments')
                }
            }
            else {
                if (inviteAllowed < totalInvite) {
                    setModalMessage(t("Candidate Limit Exhausted. Please Upgrade Your Plan."))
                    setShowModal(true)
                }
                else {
                    const res = await dispatch(sendInvites(obj));
                    if (res?.type === 'SEND_INVITES') {
                        navigate('/assessments')
                    }
                }
            }
            setLoading(false);
        }
    }




    const generateLink = () => {
        if (canInvite) {
            const encript = btoa(location.state.testId + '|' + localStorage.getItem('userId'));
            setGeneratetestUrl(window.location.protocol + '//' + window.location.host + "/candidate-invite-test/" + encript);
        }
        else {
            setShowModal(true);
            setModalMessage(t('Please upgrade your plan to generate link'))
        }

    }

    const copyLink = () => {
        var copyText = document.getElementById("gen-link");
        let text = document.getElementById("gen-link").innerHTML;

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText);
        setSuccessMessage(true);
        setsuccessMessageText(t('Link Copied'));
    }

    const snackClosed = () => {
        setSuccessEnable(false)
        setSuccessMessage(false);
        setsuccessMessageText('');
    }

    const openList = () => {
        setIsList(true)
    }


    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        console.log(e.target.files[0])
        setErrors([]);
        setFile(e.target.files[0]);
        e.preventDefault();

        if (e.target.files[0]) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(e.target.files[0]);
        }
    };

    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        const emailSet = new Set(); // To store unique email addresses
        const array = csvRows.map(i => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                let value = values[index];
                // Remove trailing \r characters
                value = value?.replace(/\r$/, '');
                object[header] = value;
                return object;
            }, {});

            // Email validation
            if (!isValidEmail(obj['EmailId'])) {
                obj['EmailId'] !== undefined && setErrors(prevErrors => [...prevErrors, `Invalid email: ${obj['EmailId']}`]);
                return null; // Skip this row
            }

            // Check for duplicate email
            if (emailSet.has(obj['EmailId'])) {
                setErrors(prevErrors => [...prevErrors, (t('Duplicate email found:')) `${obj['EmailId']}`]);
                return null; // Skip this row
            }
            emailSet.add(obj['EmailId']);

            return obj;
        });

        // Remove invalid or duplicate rows
        const nonEmptyArray = array.filter(row => row !== null && row['Name'] !== '');
        console.log(nonEmptyArray)
        const inviteAllowed = accountDetail?.accessMatrix?.candidateInvites;
        const remainingCandidateInvite = accountDetail?.accessMatrix?.candidateInvites - accountDetail?.candidateInviteCount;

        if (inviteAllowed == -1) {
            setArray(nonEmptyArray);
            setTenentEmail([]);
            setEmails([])
        }
        else {
            // setArray(nonEmptyArray);
            if (nonEmptyArray?.length < remainingCandidateInvite) {
                setArray(nonEmptyArray);
                setTenentEmail([]);
                setEmails([])
            } else {
                console.log(t('Candidate Limit Exhausted. Please Upgrade Your Plan.'))
                setModalMessage((t('Candidate Limit Of '))`${remainingCandidateInvite}` (t('Exhausted. Please Upgrade Your Plan.')))
                setShowModal(true)
                // setSuccessEnable(true);
                // setsuccessMessageText('Your balance credit is less')
            }
        }


    };

    const isValidEmail = email => {
        // Regular expression for email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const removeUser = (el) => {
        const filtered = array.filter(e => e.EmailId !== el.EmailId)
        setArray(filtered)
    }

    const handleModal = () => {
        setShowModal(false);

    }



    return (
        <div className='gappeo-test-results'>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={successMessage}
                onClose={snackClosed}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={successEnable}
                onClose={snackClosed}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>

            <Dialog
                className='screen-loader'
                open={loading}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {t('Loading..',{ns: 'home'})}
            </Dialog>
            {!isTemplate ?
                <Fragment>
                    <Stepper
                        type='preview'
                        goBack={goBack}
                        myTest={location.state.myTest}
                    />

                    <div className='gappeo-test-lists test-preview'>
                        <div className='gappeo-preview'>
                            {
                                window.innerWidth > 767 ?
                                    <div className='preview-header'>
                                        <div>
                                            <p>{t('Assessment Summary',{ns : 'home'})}</p>
                                            <h3 >{testDetails?.testName || location.state.testDetails?.testName} &nbsp; {!location.state.myTest && <img src='/images/edit-icon.svg' className='cursor-pointer' alt='' onClick={openAddModal} />}</h3>
                                        </div>
                                        <div>
                                            <h2>{location.state.testDetails?.testDetails?.tests?.length || testDetails?.testDetails?.tests?.length}&nbsp;&nbsp;<span>{t('Tests',{ns :'home'})}</span></h2>
                                        </div>
                                        <div>
                                            <h2>{testDetails?.questionCount || location.state.testDetails?.questionCount}&nbsp;&nbsp;<span>{t('Questions',{ns : 'home'})}</span></h2>
                                        </div>
                                        <div>
                                            <h2>{testDetails?.duration || location.state.testDetails?.duration}&nbsp;&nbsp;<span>{t('Minutes',{ns : 'home'})}</span></h2>
                                        </div>
                                        <div>
                                            <button className='detail-btn' onClick={() => setIsDetail(true)}>{t('View Details',{ns : 'home'})}</button>
                                            {/* <h6>Save & Exit</h6> */}
                                        </div>
                                    </div> :
                                    <div className='gappeo-mobile-header'>
                                        <div className='selected-list-header d-flex justify-content-between align-items-end'>
                                            <div>
                                                <h4>{t('Assessment Summary',{ns : 'home'})}</h4>
                                                <h4 className='d-flex justify-content-start align-items-center' style={{ color: '#fff', fontSize: 18 }}>{testDetails?.testName}&nbsp;&nbsp;
                                                    <img src='/images/edit-icon.svg' alt='' className='cursor-pointer' onClick={() => openAddModal()} /></h4>
                                                <h5 >{testDetails?.testDetails?.tests?.length} {t('test',{ns : 'home'})} | {testDetails?.questionCount} {t('Qs',{ns : 'home'})} | {testDetails?.duration} {t('Mins',{ns : 'home'})}</h5>
                                                <p>{t('Level',{ns : 'home'})}: {testDetails?.testLevelName}</p>
                                            </div>
                                            <button className='outline-btn ms-4' onClick={() => setIsDetail(true)}>{t('View Details',{ns : 'home'})}</button>
                                        </div>
                                    </div>
                            }
                            <div className='invite-details'>
                                <div>
                                    <h1>{t('Invite Team Members',{ns : "home"})}</h1>
                                    <p>{t('Invite team members for trial test (optional) or try the test yourself',{ns : "home"})}</p>
                                    <div className='email-input'>
                                        <Select isMulti
                                            placeholder={t('Enter email ids',{ns: 'home'})} options={tenentUsers}
                                            value={tenentEmail}
                                            onChange={(e) => changeTenentEmail(e)} />
                                    </div>
                                </div>
                                <div style={{ margin: '25px 0px', border: '1px solid #02C573' }}></div>
                                <div>
                                    <h1>{t('Invite Candidates',{ns : 'home'})}</h1>
                                    <div className='mt-4'>
                                        <div className='form-group'>
                                            <label>{t('Test valid until', {ns : 'home'})}</label>
                                            <div style={{ width: window.innerWidth > 767 ? '20%' : '100%' }}>
                                                <input type='date' placeholder='Select Date' min={getDateAfterTwo()} max={isDateAfter30Days()} className='form-control mt-2' value={date} onChange={(e) => {
                                                    setDate(e.target.value)
                                                    setDateError('')
                                                }} />
                                            </div>
                                            <small style={{ color: 'red' }}>{dateError}</small>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='form-group'>
                                            <label>{t('Candidate List', {ns : 'home'})}</label>
                                            <p>{t('You can invite as many candidates as the credits available',{ns : 'home'})} &nbsp;<span style={{ color: '#009199' }}>
                                                <a
                                                    href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                                                    download="samplecsv.csv"
                                                    style={{ color: '#009199' }}
                                                >
                                                    {t('Sample CSV File',{ns : 'home'})}
                                                </a>
                                            </span></p>
                                            <div className='list-header'>
                                                <div className='div1 d-flex align-items-center'>
                                                    <img src='/images/credits.png' alt='' />
                                                    <div className='count'>{accountDetail?.balanceCredit}</div>
                                                    <span>{t('Invite credits available',{ns : 'home'})}</span>
                                                </div>
                                                <div className='div2 d-flex align-items-center'>
                                                    <img src='/images/added.png' alt='' />
                                                    <div className='count'>{array?.length}</div>
                                                    <span>{t('Candidates Added',{ns : 'home'})}</span>
                                                </div>
                                                <div className='div3 d-flex align-items-center'>
                                                    <img src='/images/upload-icon-white.png' alt='' />
                                                    <div className='file-upload'>
                                                        <input type='file' accept={".csv"}
                                                            onChange={handleOnChange} />
                                                    </div>
                                                    <span style={{ fontSize: 14, fontWeight: 600 }}>
                                                        {t('Upload From File',{ns : 'home'})}
                                                    </span>
                                                </div>
                                                <div className='div3 d-flex align-items-center'>
                                                    <img src='/images/view-icon-white.png' alt='' />
                                                    <span style={{ fontSize: 14, fontWeight: 600, cursor: !array?.length ? '' : 'pointer' }} onClick={() => !array?.length ? '' : openList()}>{t('View List',{ns : 'home'})}</span>
                                                </div>
                                            </div>
                                            {/* <p>50 Candidates uploaded.<span> {t('View List',{ns : 'home'})}</span></p> */}
                                            <div className='email-input'>
                                                <CreatableSelect
                                                    isMulti placeholder={t('Enter email ids',{ns: 'home'})} onChange={(e) => changeEmail(e)} />
                                                {/* <input type='text' placeholder='Enter email ids separated by commas' className='form-control mt-2' onChange={(e) => setEmails(e.target.value)} /> */}
                                            </div>
                                            {
                                                errors && errors.map(el => {
                                                    return (
                                                        <Fragment>
                                                            <small style={{ color: 'red' }}>{el}</small>
                                                            <br />
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                            {
                                                successMsg ?
                                                    <small style={{ color: '#02c573' }}><img src='/images/tick-mark.svg' width={15} alt='' />&nbsp;{successMsg}</small> :
                                                    emailsError ?
                                                        <small style={{ color: 'red' }}>{emailsError}</small>
                                                        : null

                                            }
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='form-group'>
                                            <label>{t('Invite Link',{ns : 'home'})}</label><br />
                                            <button className='generate-btn mt-2' onClick={generateLink}> {t('GENERATE LINK', {ns : 'home'})}</button><br /><br />
                                            {generatetestUrl &&
                                                <div>
                                                    <a id='gen-link' style={{ color: "#009199" }} href={generatetestUrl} rel="noreferrer" target='_blank'>{generatetestUrl}</a>&nbsp;&nbsp;<img src='/images/copy.png' className='cursor-pointer' onClick={copyLink} alt='' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='email-template mt-4'>
                                        <div className='form-group'>
                                            <label>{t('Email template',{ns : 'home'})}</label><br />
                                            <div className='template-divs'>
                                                <div className='template-div'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='/images/invite-icon1.svg' alt='' />&nbsp;&nbsp;
                                                        <p>{t('Invitation Template',{ns : "home"})}</p>
                                                    </div>
                                                    <div className='d-flex flex-column justify-content-around align-items-center'>
                                                        <h4 className='cursor-pointer' onClick={() => dispatch(getInvitationTemplates(testId, 1, false))}> <img src='/images/view-icon.svg' alt='' />&nbsp;{t('VIEW TEMPLATE',{ns : 'home'})}</h4>
                                                        <h4 className='cursor-pointer mt-3' onClick={() => dispatch(getInvitationTemplates(testId, 1, true))} > <img src='/images/edit-icon.svg' className='cursor-pointer' alt='' />&nbsp;{t('EDIT TEMPLATE',{ns : 'home'})}</h4>
                                                    </div>
                                                </div>
                                                <div className='template-div'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='/images/reminder-icon.svg' alt='' />&nbsp;&nbsp;
                                                        <p>{t('Reminder template', {ns : 'home'})}</p>
                                                    </div>
                                                    <div className='d-flex flex-column justify-content-around align-items-center'>
                                                        <h4 className='cursor-pointer' onClick={() => dispatch(getInvitationTemplates(testId, 2, false))}> <img src='/images/view-icon.svg' alt='' />&nbsp;{t('VIEW TEMPLATE',{ns : 'home'})}</h4>
                                                        <h4 className='cursor-pointer mt-3' onClick={() => dispatch(getInvitationTemplates(testId, 2, true))}> <img src='/images/edit-icon.svg' alt='' />&nbsp;{t('EDIT TEMPLATE',{ns : 'home'})}</h4>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div style={{ margin: '25px 0px', border: '1px solid #02C573' }}></div>
                                <button className='invite-btn' disabled={loading} onClick={sendInvite}>{t('SEND INVITES',{ns : "home"})}</button>
                            </div>

                        </div>
                    </div>
                </Fragment> :
                <Template
                    testId={location.state.testId}
                />
            }


            <AddTestModal
                edit={true}
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                testName={testName}
                testNameErr={testNameErr}
                setTestName={(e) => setTestName(e)}
                setTestNameErr={(e) => setTestNameErr(e)}
                description={description}
                setDescription={(val) => setDescription(val)}
                goToQuestion={goToQuestion}
            />


            {/* //Details Modal */}
            <TestDetailModal
                isLoading={false}
                isDetail={isDetail}
                testDetails={testDetails}
                setIsDetail={() => setIsDetail(false)}
            />

            {/* candidate list model */}
            <Modal
                show={isList}
                size={window.innerWidth > 767 ? "lg" : 'md'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"candidate-list"}
            >
                <Modal.Body>
                    <div className='sticky-close'>
                        <img src='/images/close.svg' alt='' onClick={() => setIsList(false)} />
                    </div>
                    <h3>{t('Candidate List',{ns : 'home'})}</h3>
                    <div className='candidate-table mt-3'>
                        <Table responsive>
                            {array.map((item) => (
                                <tr key={item.id}>
                                    {Object.values(item).map((val) => (
                                        <td>{val}</td>
                                    ))}
                                    <td><img src='/images/delete-icon.svg' alt='' onClick={() => removeUser(item)} /></td>
                                </tr>
                            ))}
                            {/* {
                                arr.map(el => {
                                    return (
                                        <tr>
                                            <td>Rajat</td>
                                            <td>rajat@gmail.com</td>
                                            <td><img src='/images/delete-icon.svg' alt='' /></td>
                                        </tr>
                                    )
                                })
                            } */}
                        </Table>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal
                show={showModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"detail-modal"}
            >
                <div className="modal-header" style={{ background: "#009199" }}>
                    <h5 className="modal-title">{"Upgrade"} </h5>

                    <div className="sticky-close" onClick={handleModal}>
                        <img src="/images/close-white.svg" width="15px" alt="" />
                    </div>
                </div>
                <Modal.Body style={{ padding: "22px", textAlign: "center" }}>
                    <h1>😃</h1>
                    <h3>{modalMessage}</h3>
                </Modal.Body>
            </Modal>


        </div >
    )
}
