import React, { Fragment, useEffect, useState } from 'react';
import './testResult.scss';
import { useSelector } from 'react-redux';
import { Editor } from '../../../utils/editor';
import { getQuestionTypes, getQuestionLevels, resetOptions, setOptionValues, setRightAnswer, setErrors, savedQuestion, editedQuestion, setQuestionValues, resetQuestion } from '../../../Redux/ActionCreators/testLibraryAction';
import { getSkills, getSkillTopics, emptyTopics } from '../../../Redux/ActionCreators/myTestsAction';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='' alt='' />
        </components.DropdownIndicator>
    );
};

export default function CreateQuestion() {
    const [quesLevel, setQuesLevel] = useState(1);
    const [quesType, setQuesType] = useState(1);
    const [title, setTitle] = useState('');
    const [questionTitleError, setQuestionTitleError] = useState('');
    const [successMessageText, setsuccessMessageText] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [skill, setSkill] = useState(null);
    const [topic, setTopic] = useState(null);
    const [duration, setDuration] = useState(1);
    const [selectType, setSelectType] = useState('score');
    const [score, setScore] = useState('');
    const { t } = useTranslation();
    const editorRef = React.useRef();
    const { questionType, questionLevel, qTitle, questionOptions, } = useSelector(state => state.testLibrary);
    const { skills, topics, } = useSelector(state => state.myTests);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const firstRender = async () => {
            await dispatch(resetQuestion());
            if (location.state.edit) {
                const { detail } = location.state;
                window.addEventListener('onbeforeunload', dispatch(setQuestionValues(detail)))
                setQuesType(detail?.questionTypeID);
                setQuesLevel(detail?.questionLevelId);
                setSelectType(detail?.score === 0 ? 'na' : 'score')
                setScore(detail?.score)
                detail?.skillName === null ? setSkill('') : setSkill({ label: detail.skillName, value: detail?.skillID });
                detail?.skillName !== null && await dispatch(getSkillTopics(1, detail.skillID));
                detail?.skillTopicName === null ? setTopic('') : await setTopic({ label: detail.skillTopicName, value: detail?.skillTopicId });
                await setDuration(detail.duration);
            } else {

            }
            dispatch(getSkills(1));
            dispatch(getQuestionTypes());
            dispatch(getQuestionLevels());
        }
        firstRender();
        return () => {
            window.removeEventListener('beforeunload', dispatch(setQuestionValues(location.state.detail)))
        }
    }, []);

    const handleSkill = async (e) => {
        await setSkill(e);
        setTopic(null);
        if (!e.__isNew__) {
            await dispatch(getSkillTopics(1, e.value));
        } else {
            await dispatch(emptyTopics())
        }
    }

    const handleTopic = (e) => {
        setTopic(e)
    }

    const changeQuestionType = (e) => {
        setQuesType(Number(e.target.value));
        dispatch(resetOptions());
    }

    const changeQuestionLevel = (e) => {
        setQuesLevel(e.target.value)
    }

    const onChangeOption = (key, idx, value) => {
        dispatch(setOptionValues(key, idx, value));
    }

    const handleOptionChange = (e) => {
        setSelectType(e.target.value);
        setScore(e.target.value === 'na' ? 0 : '')
    }


    const saveQuestion = async () => {
        if (!skill) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please select at least one Knowledge area'));
        }
        else if (!topic) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please select at least one Topic'));
        }
        else if (!duration) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please enter duration'));
        }
        else if (quesType === 5 && score === '') {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please enter score'));
        }
        else if (qTitle.trim() === "" || null) {
            setQuestionTitleError(t(`Question title  can't empty`))
        }
        else if (quesType !== 5 && questionOptions[0].questionOption === '') {
            dispatch(setErrors(0));
        }
        else if (quesType !== 5 && questionOptions[1].questionOption === '') {
            dispatch(setErrors(1));
        }
        else if (quesType !== 6 && quesType !== 5 && questionOptions[2].questionOption === '') {
            dispatch(setErrors(2))
        }
        else if (quesType !== 6 && quesType !== 5 && questionOptions[3].questionOption === '') {
            dispatch(setErrors(3))
        }
        else if (((quesType === 1) || (quesType === 6)) && !questionOptions.filter(option => option.iscorrect)?.length) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please select at least one correct option'));
        }
        else if ((quesType === 2) && !questionOptions.filter(option => option.iscorrect)?.length) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please select at least one correct option'));
        }
        else {
            let obj
            if (quesType === '5') {
                obj = location.state.edit ?
                    {
                        "questionID": location.state.detail?.questionID,
                        "question": qTitle,
                        "questionTypeID": quesType,
                        "questionLevelId": quesLevel,
                        "questionTag": "",
                        "isPreQualifierQuestions": true,
                        "questionOptions": questionOptions?.map(el => [{ questionID: el.questionID, questionOptionID: el.questionOptionID, questionOption: el.questionOption, iscorrect: el.iscorrect }][0]),
                        "skillId": (skill?.value === skill?.label) ? 0 : skill?.value,
                        "skillName": skill?.label,
                        "skillTopicId": (topic?.value === topic.label) ? 0 : topic?.value,
                        "skillTopicName": topic?.label,
                        "duration": duration,
                        "score": score
                    } :
                    {
                        "question": qTitle,
                        "questionTypeID": quesType,
                        "questionLevelId": quesLevel,
                        "questionTag": "",
                        "isPreQualifierQuestions": true,
                        "questionOptions": quesType === '6' ? questionOptions?.slice(0, 2)?.map(el => [{ questionID: el.questionID, questionOptionID: el.questionOptionID, questionOption: el.questionOption, iscorrect: el.iscorrect }][0]) : questionOptions?.map(el => [{ questionID: el.questionID, questionOptionID: el.questionOptionID, questionOption: el.questionOption, iscorrect: el.iscorrect }][0]),
                        "skillId": (skill?.value === skill?.label) ? 0 : skill?.value,
                        "skillName": skill?.label,
                        "skillTopicId": (topic?.value === topic.label) ? 0 : topic?.value,
                        "skillTopicName": topic?.label,
                        "duration": duration,
                        "score": score
                    }
            } else {
                obj = location.state.edit ?
                    {
                        "questionID": location.state.detail?.questionID,
                        "question": qTitle,
                        "questionTypeID": quesType,
                        "questionLevelId": quesLevel,
                        "questionTag": "",
                        "isPreQualifierQuestions": true,
                        "questionOptions": questionOptions?.map(el => [{ questionID: el.questionID, questionOptionID: el.questionOptionID, questionOption: el.questionOption, iscorrect: el.iscorrect }][0]),
                        "skillId": (skill?.value === skill?.label) ? 0 : skill?.value,
                        "skillName": skill?.label,
                        "skillTopicId": (topic?.value === topic.label) ? 0 : topic?.value,
                        "skillTopicName": topic?.label,
                        "duration": duration
                    } :
                    {
                        "question": qTitle,
                        "questionTypeID": quesType,
                        "questionLevelId": quesLevel,
                        "questionTag": "",
                        "isPreQualifierQuestions": true,
                        "questionOptions": quesType === '6' ? questionOptions?.slice(0, 2)?.map(el => [{ questionID: el.questionID, questionOptionID: el.questionOptionID, questionOption: el.questionOption, iscorrect: el.iscorrect }][0]) : questionOptions?.map(el => [{ questionID: el.questionID, questionOptionID: el.questionOptionID, questionOption: el.questionOption, iscorrect: el.iscorrect }][0]),
                        "skillId": (skill?.value === skill?.label) ? 0 : skill?.value,
                        "skillName": skill?.label,
                        "skillTopicId": (topic?.value === topic.label) ? 0 : topic?.value,
                        "skillTopicName": topic?.label,
                        "duration": duration
                    }
            }


            if (!location.state.edit) {
                const res = await dispatch(savedQuestion(obj));
                if (res?.type === 'SAVED_QUESTION') {
                    location?.state?.question ?
                        navigate(-1)
                        : navigate('/home/add-question', { state: { isQuestionVisible: true } });
                }
            } else {
                const res = await dispatch(editedQuestion(obj));
                if (res?.type === 'EDITED_QUESTION') {
                    location?.state?.question ?
                        navigate(-1)
                        : navigate('/home/add-question', { state: { isQuestionVisible: true } });
                }
            }
        }
    }


    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    const config = {
        toolbarInlineForSelection: true,
        toolbar: true,
        tabIndex: 1,
        placeholder: '',
        readonly: false,// all options from https://xdsoft.net/jodit/doc/
        statusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        height: 100,
        width: '100%',
        toolbarSticky: false,
        toolbarDisableStickyForMobile: false,
        allowResizeX: false,
        allowResizeY: false,
        uploader: {
            url: `${process.env.REACT_APP_API_KEY}admin/my-content/upload/image`,
            headers: { Authorization: AuthStr },
            filesVariableName: function (e) {
                return "image";
            },
            insertImageAsBase64URI: false,
            prepareData: function (data) {
                // data.append("image", );
                return data;
            },
            isSuccess: function (resp) {
                return resp;
            },
            process: function (resp) {
                return {
                    files: resp.image,
                    path: resp.image,
                    baseurl: resp.image,
                    error: resp.error,
                    message: resp.message,
                };
            },

            defaultHandlerSuccess: function (data) {
                if (this.componentName === 'jodit-uploader') {
                    this.jodit.selection.insertImage(data.baseurl, null, 100);
                }
                else {
                    this.selection.insertImage(data.baseurl, null, 100);
                }
            }
        },
        autoFocus: true,
        removeButtons: ['eraser', 'fullsize'],
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        defaultActionOnPasteFromWord: 'insert_as_html',
        enableDragAndDropFileToEditor: true,
        usePopupForSpecialCharacters: true,
        // cleanHTML: { removeEmptyElements: false, },
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        buttons: [
            'source', '|',
            'insertFormula',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'undo', 'redo', '|',
        ],
        buttonsMD: [
            'source', '|',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'undo', 'redo', '|',
        ],
        buttonsSM: [
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'fontsize',
            'brush',
            'superscript',
            'subscript',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
        ],
        buttonsXS: [
            'bold',
            'italic',
            'underline', '|',
            'brush',
            'paragraph', '|',
            'image',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
            'eraser'
        ],
    }

    const snackClosed = () => {
        setSuccessMessage(false);
        setsuccessMessageText('');
    }
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={successMessage}
                onClose={snackClosed}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            <div className="bg-white rounded mt-3 mb-3 px-5 py-3 create-question-main" >

                <div className="row">
                    <div className="col-4">
                        <img src='/images/back.svg' alt='' className='cursor-pointer' onClick={() => navigate(-1)} />
                    </div>
                    <div className="col-8">
                        <h1 className="maintxt"> {location.state?.view ? (t('View Question')) : location.state?.edit ? (t('Edit Question')) : (t('Create Question'))}</h1>
                    </div>

                </div>


                <div className={window.innerWidth > 767 ? "row px-5" : 'row px-2'} style={{ pointerEvents: location.state.view ? 'none' : 'auto' }}>
                    <div className="col-12">
                        <div className="row   mb-1">
                            <div className="col-md-6 col-12">
                                <div className="row mt-4 mb-2">
                                    <div className="col-12"><label className="labeltext px-0">*{t('Knowledge Area', { ns: 'home' })}</label><br /></div>
                                </div>
                                <div className='mt-2'>
                                    <CreatableSelect
                                        options={skills}
                                        components={DropdownIndicator}
                                        value={skill}
                                        placeholder={t('Select Knowledge Area', { ns: 'home' })}
                                        onChange={(e) => handleSkill(e)} />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="row mt-4 mb-2">
                                    <div className="col-12"><label className="labeltext px-0">*{t('Topics/tags', { ns: 'home' })}</label><br /></div>
                                </div>
                                <div className='mt-2'>
                                    <CreatableSelect
                                        components={DropdownIndicator}
                                        options={topics}
                                        value={topic}
                                        placeholder={t('Select Topic/tags', { ns: 'home' })}
                                        onChange={(e) => handleTopic(e)} />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4 mb-2">
                            <div className="col-12"><label className="labeltext px-0">*{t('Question Type', { ns: 'home' })}</label><br /></div>
                        </div>
                        <div className="row   mb-1">
                            <div className="col-12">
                                {
                                    questionType?.filter(el => ((el.questionTypeID === 1) || (el.questionTypeID === 5)))?.map((el, i) => {
                                        return (
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name='radio' disabled={location.state.edit} checked={Number(quesType) === el.questionTypeID ? true : false}
                                                    id={el.questionTypeName} value={el.questionTypeID} onChange={(e) => changeQuestionType(e)}
                                                />

                                                <label className="form-check-label" >{el.questionTypeName}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>


                        <div className="row mt-4 mb-2">
                            <div className="col-12"><label className="labeltext px-0">*{t('Question Level', { ns: 'home' })}</label><br /></div>
                        </div>

                        <div className="row mt-2  mb-2">
                            <div className="col-12">
                                {
                                    questionLevel?.map((el, i) => {
                                        return (
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name='radio1' checked={Number(quesLevel) === el.questionLevelID ? true : false}
                                                    id={el.questionLevelName} value={el.questionLevelID} onChange={(e) => changeQuestionLevel(e)}
                                                />
                                                <label className="form-check-label" >{el.questionLevelName}</label>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>

                        <div className="row mt-4 mb-2">
                            <div className="col-12"><label className="labeltext px-0">*{t('Duration (mins)', { ns: 'home' })}</label><br /></div>
                        </div>

                        <div className="row mt-2  mb-2">
                            <div className="col-md-6 col-12">
                                <input type='number' className='form-control' value={duration} minLength={0} onChange={(e) => setDuration(e.target.value)} required />

                            </div>
                        </div>
                        {quesType === 5 ?
                            <Fragment>
                                <div className="row mt-4 mb-2">
                                    <div className="col-12"><label className="labeltext px-0">*{t('Score', { ns: 'home' })}</label><br /></div>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name='radio2' checked={selectType === 'score' ? true : false}
                                        id='score' onChange={handleOptionChange} value='score'
                                    />

                                    <label className="form-check-label" >{t('Score', { ns: 'home' })}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name='radio2' checked={selectType === 'na' ? true : false}
                                        id={'score1'} onChange={handleOptionChange} value='na'
                                    />

                                    <label className="form-check-label" >N/A</label>
                                </div>
                                {selectType === 'na' ? null :
                                    <div className="row mt-2  mb-2">
                                        <div className="col-md-6 col-12">
                                            <input type='number' className='form-control' disabled={selectType === 'na'} value={selectType === 'na' ? 0 : score} minLength={0} onChange={(e) => setScore(e.target.value)} required />

                                        </div>
                                    </div>}
                            </Fragment>
                            : null
                        }


                        <div className="row mt-4 mb-2">
                            <div className="col-12"><label className="labeltext px-0">*{t('Question Title', { ns: 'home' })}</label><br /></div>
                        </div>
                        <div className="row   mb-2">
                            <div className="col-12">
                                <Editor content={title} type='title' />
                                <small style={{ color: 'red' }}>{!qTitle && questionTitleError}</small>
                            </div>
                        </div >

                        {Number(quesType) === 5 ? null :
                            Number(quesType) === 6 ?
                                <Fragment>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-12"><label className="labeltext px-0">*{t('Question Options', { ns: 'home' })}</label><br /></div>
                                    </div >
                                    <div className="row ">
                                        <div className="col-12">

                                            <table className="table table-hover right-ans-desc-main" >

                                                <tr>
                                                    <th scope="col" style={{
                                                        paddingBottom: 9, textAlign: 'center'
                                                    }}> <label className="table-th"
                                                        style={{ color: '#009199', background: '#fff', paddingBottom: 2 }}>{t('Right Ans', { ns: 'home' })}</label></th>

                                                    <th scope="col" style={{ paddingBottom: 9 }}><label className="table-th" style={{ color: '#009199', background: '#fff', paddingBottom: 2 }}>
                                                        {t('Choice description', { ns: 'home' })}</label><br /></th>

                                                </tr>



                                                {questionOptions && questionOptions.slice(0, 2)?.map((el, i) => {
                                                    return (
                                                        <tr>
                                                            <td style={{ width: '17%', textAlign: 'center' }}>
                                                                <input id="0" type="checkbox" name="0" value={el.index} checked={el.iscorrect ? true : false}
                                                                    className="form-check-input" onChange={(e) => dispatch(setRightAnswer(i, el.index, el.iscorrect, quesType))} />

                                                            </td>


                                                            <td>

                                                                <div className='options-holder w-100 mt-3'>
                                                                    <JoditEditor
                                                                        ref={editorRef}
                                                                        value={el.questionOption}
                                                                        config={config}
                                                                        tabIndex={1} // tabIndex of textarea
                                                                        onBlur={newContent => onChangeOption(i, el.index, newContent)} // preferred to use only this option to update the content for performance reasons
                                                                        onChange={newContent => { }}
                                                                    />
                                                                </div>
                                                                {<small style={{ color: 'red', fontWeight: 'normal' }}>{el.optionError}</small>}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                }

                                                <tr className="h-10">
                                                    <td colspan="3" style={{ lineHeight: 1 }}> &nbsp;
                                                    </td>
                                                </tr>

                                            </table>
                                        </div >
                                    </div >
                                </Fragment>
                                : <Fragment>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-12"><label className="labeltext px-0">*{t('Question Options', { ns: 'home' })}</label><br /></div>
                                    </div >
                                    <div className="row ">
                                        <div className="col-12">

                                            <table className="table table-hover right-ans-desc-main" >

                                                <tr>
                                                    <th scope="col" style={{
                                                        paddingBottom: 9, textAlign: 'center'
                                                    }}> <label className="table-th"
                                                        style={{ color: '#009199', background: '#fff', paddingBottom: 2 }}>{t('Right Ans', { ns: 'home' })}</label></th>

                                                    <th scope="col" style={{ paddingBottom: 9 }}><label className="table-th" style={{ color: '#009199', background: '#fff', paddingBottom: 2 }}>
                                                        {t('Choice description', { ns: 'home' })}</label><br /></th>

                                                </tr>



                                                {questionOptions && questionOptions?.map((el, i) => {
                                                    return (
                                                        <tr>
                                                            <td style={{ width: '17%', textAlign: 'center' }}>
                                                                {Number(quesType) === 2 ?
                                                                    <input id="0" type="checkbox" name="0" value={el.index}
                                                                        className="form-check-input" onChange={(e) => dispatch(setRightAnswer(i, el.index, el.iscorrect, quesType))} />
                                                                    :
                                                                    <input id="0" type="checkbox" name="0" value={el.index} checked={el.iscorrect ? true : false}
                                                                        className="form-check-input" onChange={(e) => dispatch(setRightAnswer(i, el.index, el.iscorrect, quesType))} />
                                                                }

                                                            </td>
                                                            <td>
                                                                <div className='options-holder w-100 mt-3'>
                                                                    <JoditEditor
                                                                        ref={editorRef}
                                                                        value={el.questionOption}
                                                                        config={config}
                                                                        tabIndex={1} // tabIndex of textarea
                                                                        onBlur={newContent => onChangeOption(i, el.index, newContent)} // preferred to use only this option to update the content for performance reasons
                                                                        onChange={newContent => { }}
                                                                    />
                                                                </div>
                                                                {<small style={{ color: 'red', fontWeight: 'normal' }}>{el.optionError}</small>}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                }

                                                <tr className="h-10">
                                                    <td colspan="3" style={{ lineHeight: 1 }}> &nbsp;
                                                    </td>
                                                </tr>

                                            </table>
                                        </div >
                                    </div >
                                </Fragment>}

                        {location.state.view ? null :
                            <div className="row py-2" >
                                <div className="col-12">

                                    <button className={window.innerWidth > 767 ? "btn-txt1 w-25" : 'btn-txt1 w-50'} onClick={saveQuestion}>{t('Save', { ns: 'home' })}</button>

                                </div>
                            </div >}

                    </div >
                </div >
            </div >
        </div >
    )
}